import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const LogoBox = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: 96,
        height: 34,
        position: 'relative',
        mt: 2,
        cursor: 'pointer',
      }}
      onClick={() => navigate('/')}
    >
      <img
        src={theme.resources.logo.src}
        alt="Tournament Entry System"
        style={{ maxWidth: '100%', minHeight: '100%' }}
      />
    </Box>
  );
};
