import { request } from '@services/request';
import { API_PATH } from '@constants/api';
import { ApiMethod } from '@services/api/index';
import { CheckoutResponse, CheckoutSeasonType, CheckoutTournamentsType, SeasonCheckoutType, TournamentCheckoutType } from '@typings/checkout.types';

const getCheckoutTournaments = (tournamentsIds: string[]): Promise<{ data: CheckoutTournamentsType }> =>
  request(`${API_PATH.checkout}/tournament-entry?tournamentIds=${tournamentsIds}`, ApiMethod.GET);

const postCheckoutTournaments = (checkout: TournamentCheckoutType): Promise<CheckoutResponse> =>
  request(`${API_PATH.checkout}/tournament-entry`, ApiMethod.POST, checkout);

const getCheckoutSeasonMembership = (): Promise<{ data: CheckoutSeasonType }> =>
  request(`${API_PATH.checkout}/season-membership`, ApiMethod.GET);

const postCheckoutSeasonMembership = (checkout: SeasonCheckoutType): Promise<CheckoutResponse> =>
  request(`${API_PATH.checkout}/season-membership`, ApiMethod.POST, checkout);

export const checkoutApi = {
  getCheckoutTournaments,
  postCheckoutTournaments,
  getCheckoutSeasonMembership,
  postCheckoutSeasonMembership
};
