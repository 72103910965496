import * as yup from 'yup';
import PostcodeValidationSchema from '@validations/PostcodeValidationSchema';
import PhoneValidationSchema from '@validations/PhoneValidationSchema';

const CheckoutValidationSchema = yup.object().shape({
  firstnames: yup
    .string()
    .max(20, {
      message: 'First Name field can have only 20 characters',
    })
    .required('First Name is required field'),
  lastName: yup
    .string()
    .max(20, {
      message: 'Last Name field can have only 20 characters',
    })
    .required('Last Name is required field'),
  address1: yup
    .string()
    .max(50, {
      message: 'Address field can have only 50 characters',
    })
    .required('Address is required field'),
  address2: yup.string().max(50, {
    message: 'Address field can have only 50 characters',
  }),
  city: yup
    .string()
    .max(40, {
      message: 'City field can have only 40 characters',
    })
    .required('City is required field'),
  country2Char: yup
    .string()
    .min(1, 'Country code should contain minimum 1 character')
    .max(2, 'Country code should contain maximum 2 characters')
    .required('Country is required field'),
  postcode: PostcodeValidationSchema.required('Postcode is required field'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  emailCc: yup.string().email('Enter a valid Email Cc value'),
  phone: PhoneValidationSchema.required('Phone Number is required field'),
});

export const TournamentCheckoutValidationSchema = CheckoutValidationSchema.shape({
  termsAndConditionsAccepted: yup
    .boolean()
    .required('The terms and conditions must be accepted.')
    .oneOf([true], 'The terms and conditions must be accepted.'),
  playersContractAccepted: yup
    .boolean()
    .required('The players contract must be accepted.')
    .oneOf([true], 'The players contract must be accepted.'),
});

export const SeasonCheckoutValidationSchema = CheckoutValidationSchema.shape({
  wpbsaConstitutionAccepted: yup
    .boolean()
    .required('The WPBSA Constitution must be accepted.')
    .oneOf([true], 'The WPBSA Constitution must be accepted'),
  wpbsaMembersRulesAccepted: yup
    .boolean()
    .required('The WPBSA Members Rules and Regulations must be accepted.')
    .oneOf([true], 'The WPBSA Members Rules and Regulations must be accepted.'),
  wpbsaDisciplinaryRulesAccepted: yup
    .boolean()
    .required('The WPBSA Disciplinary Rules must be accepted.')
    .oneOf([true], 'The WPBSA Disciplinary Rules must be accepted.'),
});
