import useSWR from 'swr';
import { ItemStringValueFetcher } from '@services/index';
import { TransactionStatusType } from '@typings/billings.types';
import { billingsApi } from '@services/api/billings';

const itemFetcher: ItemStringValueFetcher<TransactionStatusType> = async (id: string) =>
  await billingsApi.getTransactionStatus(id);

export function useTransactionStatus(id: string) {
  const { data, error, mutate } = useSWR([id, 'get-transactions-status'], itemFetcher);

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
}
