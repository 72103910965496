import { BookingStatusType } from '@typings/practice.types';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/material';

interface BookingIconProps {
  status: BookingStatusType;
}
const BookingIcon = ({ status }: BookingIconProps) => {
  const theme = useTheme();
  const sizesProps: SxProps = {
    height: theme.spacing(3.5),
    width: theme.spacing(3.5),
    margin: 'auto',
  };
  if (
    status === 'UnAvailable' ||
    status === 'BookedByUsers' ||
    status === 'BlockedByCooldown' ||
    status === 'BlockedByMaxAllowed'
  ) {
    return <DoDisturbIcon sx={sizesProps} />;
  }

  if (status === 'BookedByCurrentUser') {
    return <DoneIcon sx={sizesProps} />;
  }

  return <AddIcon sx={sizesProps} />;
};

export default BookingIcon;
