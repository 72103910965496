import Contact from '@/pages/contact';

export const ContactRoutes = [
  {
    path: '',
    element: <Contact />,
    exact: true,
    handle: { title: 'Contact', header: 'Contact' },
  },
];
