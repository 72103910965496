import {
  BoldTypography,
  CartDrawer,
  SemiBoldTypography,
  TransactionBox,
  TransactionMainTypography,
} from '@components/common/FormStyledComponents';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useTransactionStatus } from '@services/api/useTransactionStatus';
import TransactionIcon from '@components/Transactions/TransactionIcon';
import TransactionLoader from '@components/Transactions/TransactionLoader';

interface TransactionFailedProps {
  vendorTxCode: string;
}

const TransactionFailed = ({ vendorTxCode }: TransactionFailedProps) => {
  const theme = useTheme();
  const { data, isLoading } = useTransactionStatus(vendorTxCode);

  if (!data) {
    return null;
  }

  if (isLoading) {
    return <TransactionLoader />;
  }

  return (
    <CartDrawer sx={{ padding: theme.spacing(4, 3) }}>
      <TransactionBox>
        <TransactionMainTypography>
          <TransactionIcon status="failure" /> Transaction Failed
        </TransactionMainTypography>
        <SemiBoldTypography>
          Sorry, the transaction did not complete successfully for the following reason:
        </SemiBoldTypography>
        {data.data.statusDetail && (
          <BoldTypography
            sx={{ display: 'flex', justifyContent: 'center', color: theme.palette.error.dark }}
          >
            {data.data.statusDetail}
          </BoldTypography>
        )}
        <SemiBoldTypography>
          Your order number is <strong>{data.data.basketId}</strong>, please quote this number if
          you wish to follow up on this transaction.
        </SemiBoldTypography>
      </TransactionBox>
    </CartDrawer>
  );
};

export default TransactionFailed;
