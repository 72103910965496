import Typography from '@mui/material/Typography';
import { TournamentType } from '@typings/tournaments.types';
import { Box, Button } from '@mui/material';
import { formatDate } from '@utils/date';
import { DATE_FORMAT } from '@constants/date';
import {
  BoldTypography,
  SemiBoldTypography,
  StyledInfoBox,
  TournamentsDates,
} from '@components/common/FormStyledComponents';
import {
  accordionProps,
  StripedAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from '@components/Accordion/StyledAccordion';
import CheckoutFooter from '@components/Tournaments/CheckoutFooter';
import TournamentsCheckoutActions from '@components/Tournaments/TournamentsAccordion/TournamentsAccordionActions';
import EntryPackIcon from '@components/Tournaments/EntryPackIcon';

interface TournamentsAccordionProps {
  tournaments: TournamentType[];
  bookPractice?: {
    handleBooking: (tournamentId: TournamentType['tournamentId'], tournamentName: string) => void;
    checkIsTournamentFinished: (day: string) => boolean;
  };

  checkout?: {
    selectedTournaments: [] | number[];
    checkoutAction: (tournament: TournamentType) => void;
    tournamentsPrice: number;
    handleCheckout: VoidFunction;
  };
}

const TournamentsAccordion = ({
  tournaments,
  bookPractice,
  checkout,
}: TournamentsAccordionProps) => {
  const bookingHandler = (tournamentId: TournamentType['tournamentId'], name: string) => () => {
    return bookPractice?.handleBooking(tournamentId, name);
  };

  return (
    <>
      {tournaments?.map((item: TournamentType, index) => (
        <Box key={item.tournamentId}>
          <StripedAccordion square index={index}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <StyledAccordionSummary
                sx={{
                  width: '100%',
                  pl: '8px',
                  overflow: 'hidden',
                }}
                {...accordionProps}
              >
                <Typography
                  fontSize="0.9rem"
                  pl={1}
                  sx={{
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {item.name}
                </Typography>
              </StyledAccordionSummary>
              <TournamentsDates startDate={item.startsOn} endDate={item.endsOn} />

              {checkout && (
                <TournamentsCheckoutActions
                  selectedTournaments={checkout.selectedTournaments}
                  checkoutAction={checkout.checkoutAction}
                  tournament={item}
                />
              )}
            </Box>
            <StyledAccordionDetails>
              <StyledInfoBox>
                {item?.venueName && <BoldTypography>Venue:</BoldTypography>}
                <BoldTypography>Start:</BoldTypography>
                <BoldTypography>End:</BoldTypography>
                <BoldTypography>Closing date:</BoldTypography>
                <BoldTypography>Entry Fee:</BoldTypography>
                {item.isEntryPack && <BoldTypography>Entry Pack:</BoldTypography>}
                {bookPractice && bookPractice.checkIsTournamentFinished(item.endsOn) ? (
                  <BoldTypography>Practice Tables:</BoldTypography>
                ) : null}
              </StyledInfoBox>
              <StyledInfoBox>
                {item?.venueName && <Typography>{item.venueName}</Typography>}
                <Typography>{formatDate(item.startsOn, DATE_FORMAT)}</Typography>
                <Typography>{formatDate(item.endsOn, DATE_FORMAT)}</Typography>
                <Typography>{formatDate(item.entryDeadline, DATE_FORMAT)}</Typography>
                <SemiBoldTypography>£{item.entryFee}</SemiBoldTypography>
                {item.isEntryPack && (
                  <EntryPackIcon fileName={item?.name} tournamentId={item.tournamentId} />
                )}
                {bookPractice && bookPractice.checkIsTournamentFinished(item.endsOn) && !item.isPlayerKnockedOut ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={bookingHandler(item.tournamentId, item.name)}
                    sx={{ width: 'fit-content' }}
                  >
                    Book Practice
                  </Button>
                ) : null}
              </StyledInfoBox>
            </StyledAccordionDetails>
          </StripedAccordion>
        </Box>
      ))}
      {checkout && checkout.selectedTournaments.length > 0 && checkout.tournamentsPrice >= 0 && (
        <CheckoutFooter
          selectedTournaments={checkout.selectedTournaments}
          tournamentsPrice={checkout.tournamentsPrice}
          handleCheckout={checkout.handleCheckout}
        />
      )}
    </>
  );
};

export default TournamentsAccordion;
