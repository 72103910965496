import { useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { APP_PATHS } from '@/router/constants';
import TournamentsAccordion from '@components/Tournaments/TournamentsAccordion/TournamentsAccordion';
import TournamentsTable from '@components/Tournaments/TournamentsTable/TournamentsTable';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTournaments } from '@services/api/useTournaments';
import { TournamentType } from '@typings/tournaments.types';

const TournamentsAvailable = () => {
  const { data: tournaments } = useTournaments(false, true);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [selectedTournaments, setSelectedTournaments] = useState<number[] | []>([]);
  const [tournamentsPrice, setTournamentsPrice] = useState(0);
  const navigate = useNavigate();

  const handleTournamentAction = (tournament: TournamentType) => {
    const isSelected = selectedTournaments.find((selected) => selected === tournament.tournamentId);

    if (isSelected) {
      setSelectedTournaments((selectedTournaments) =>
        selectedTournaments.filter((selected) => selected !== tournament.tournamentId)
      );
      setTournamentsPrice((tournamentsPrice) => (tournamentsPrice -= tournament.entryFee));
    } else {
      setSelectedTournaments((selectedTournaments) => [
        ...selectedTournaments,
        tournament.tournamentId,
      ]);
      setTournamentsPrice((tournamentsPrice) => (tournamentsPrice += tournament.entryFee));
    }
  };

  const handleCheckout = () => {
    navigate({
      pathname: APP_PATHS.CHECKOUT.TOURNAMENT,
      search: `?${createSearchParams({
        selectedTournaments: selectedTournaments.join(','),
        tournamentsPrice: String(tournamentsPrice),
      })}`,
    });
  };

  if (matches && tournaments) {
    return (
      <TournamentsTable
        tournaments={tournaments.data}
        checkout={{
          selectedTournaments,
          checkoutAction: handleTournamentAction,
          tournamentsPrice: +tournamentsPrice.toFixed(2),
          handleCheckout,
        }}
      />
    );
  }

  return (
    <>
      {tournaments && (
        <TournamentsAccordion
          tournaments={tournaments.data}
          checkout={{
            selectedTournaments,
            checkoutAction: handleTournamentAction,
            tournamentsPrice: +tournamentsPrice.toFixed(2),
            handleCheckout,
          }}
        />
      )}
    </>
  );
};

export default TournamentsAvailable;
