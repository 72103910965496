import { TextField, TextFieldProps } from '@mui/material';
import React, { FC } from 'react';
import { ErrorLabel } from '@components/layout/StyledTextField/StyledTextField';
import { useTheme } from '@mui/material/styles';

type StyledDisabledTextFieldProps = Omit<TextFieldProps, 'error'> & {
  error?: string | boolean;
  testId?: string;
};

export const StyledDisabledTextField: FC<StyledDisabledTextFieldProps> = ({
  error,
  testId,
  ...props
}) => {
  const theme = useTheme();
  return (
    <>
      <TextField
        data-testid={testId}
        variant="standard"
        disabled
        spellCheck="false"
        autoComplete="false"
        inputProps={{
          '&:-internal-autofill-selected': {
            backgroundColor: 'black !important',
          },
        }}
        sx={{
          width: '100%',
          textTransform: 'capitalize',
          '& .MuiInput-root.Mui-disabled:before': {
            borderBottom: 'none',
          },
          input: {
            '-webkit-text-fill-color': `${
              theme.palette.mode === 'dark'
                ? theme.palette.common.white
                : theme.palette.common.black
            } !important`,
          },
        }}
        {...props}
      />
      {!!error && typeof error === 'string' && <ErrorLabel>{error}</ErrorLabel>}
    </>
  );
};
