import SponsorsList from '@components/Sponsors/SponsorsList';
import { useSponsors } from '@services/api/useSponsors';

const Sponsors = () => {
  const { data: sponsors, isLoading } = useSponsors();

  let content;

  if (isLoading) {
    content = <p>Loading...</p>;
  } else if (!sponsors?.data.items?.length) {
    content = <p>Not found any sponsors.</p>;
  } else {
    content = <SponsorsList sponsors={sponsors.data.items} />;
  }

  return <>{content}</>;
};

export default Sponsors;
