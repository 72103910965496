import React, { FC, MouseEventHandler } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

interface DrawerLinkProps {
  text: string;
  url: string;
  openInNewWindow?: boolean;
  icon?: React.ReactElement<any, any>;
  onClick?: MouseEventHandler<HTMLElement> | undefined;
}

const DrawerLink: FC<DrawerLinkProps> = ({ text, url, openInNewWindow, icon, onClick }) => {
  if (icon) {
    return (
      <>
        <ListItemButton
          sx={{ textAlign: 'center', justifyContent: 'center' }}
          onClick={(e) => onClick?.(e)}
        >
          <ListItemIcon sx={{ minWidth: 32 }}>{icon}</ListItemIcon>
          {text == '' ? null : <ListItemText primary={text} sx={{ flexGrow: 0, pr: 4 }} />}
        </ListItemButton>
        <Divider />
      </>
    );
  }

  return (
    <>
      <ListItemButton
        component="a"
        href={url}
        target={openInNewWindow ? '_blank' : undefined}
        rel={openInNewWindow ? 'noopener noreferrer' : undefined}
        sx={{ textAlign: 'center' }}
      >
        <ListItemText primary={text} />
      </ListItemButton>
      <Divider />
    </>
  );
};

export default DrawerLink;
