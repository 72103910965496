import { useSnackbar } from 'notistack';
import React, { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { APP_PATHS } from '@/router/constants';
import AccountMenu from '@components/layout/TopMenu/AccountMenu';
import AvatarLink from '@components/layout/TopMenu/AvatarLink';
import Links from '@components/layout/TopMenu/Links';
import { AuthContext, AuthContextValue } from '@contexts/auth';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, PaletteMode, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { accountsApi } from '@services/api/accounts';
import { ApiError } from '@services/apiError';
import { signOut } from '@services/authorization';
import { RevokeToken } from '@typings/common';

import CustomDrawer from './CustomDrawer';

export interface NavBarProps {
  themeMode: PaletteMode;
  toggleMode: () => void;
}

const TopMenu: FC<NavBarProps> = ({ themeMode, toggleMode }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    state: { refreshToken, userName },
  } = useContext<AuthContextValue>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const [drawerState, setDrawerState] = useState<boolean>(false);
  const isAccountMenuOpen = Boolean(anchorEl);

  const handleAccountMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerState(open);
  };

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRevokeToken = async () => {
    if (!refreshToken) return;

    const revokeToken: RevokeToken = {
      refreshToken,
    };

    try {
      await accountsApi.revokeToken(revokeToken);
    } catch (e) {
      return enqueueSnackbar((e as ApiError).title, { variant: 'error' });
    }
  };

  const handleLogout = async () => {
    await handleRevokeToken();
    await signOut();
    navigate(APP_PATHS.ACCOUNT.LOGIN);
  };

  const handleChangePassword = () => {
    navigate(APP_PATHS.PROFILE.CHANGE_PASSWORD);
  };

  const handleProfile = () => {
    navigate(APP_PATHS.PROFILE.ROOT);
  };

  return (
    <React.Fragment>
      <Grid
        container
        sx={{
          backgroundColor: theme.header.background,
          color: theme.header.text,
          borderBottom: theme.header.divider ? `1px solid ${theme.palette.divider}` : 'none',
          height: 72,
        }}
      >
        <Grid item xs={4} lg={2}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: theme.resources.logo.background,
              width: 95,
              height: '100%',
              padding: 2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                position: 'relative',
              }}
            >
              <img
                src={theme.resources.logo.src}
                alt="Tournament Entry System"
                style={{ maxWidth: '100%', minHeight: '100%' }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={8}
          display={{ xs: 'none', lg: 'flex' }}
          sx={{
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            typography: 'body1',
            '& > :not(style) + :not(style)': {
              ml: 2,
            },
          }}
        >
          <Links />
        </Grid>
        <Grid
          item
          xs={8}
          lg={2}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            textAlign: 'center',
            pr: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: '.875rem',
              color: theme.palette.text.secondary,
              fontWeight: 500,
              pr: 1,
            }}
          >
            {userName}
          </Typography>
          <AvatarLink
            handleAvatarClick={handleAvatarClick}
            isAccountMenuOpen={isAccountMenuOpen}
            username={userName}
          />
          <IconButton
            onClick={toggleDrawer(true)}
            aria-label="menu"
            sx={{ display: { xs: 'flex', lg: 'none' }, opacity: drawerState ? 0 : 1 }}
          >
            <MenuIcon fontSize="large" sx={{ color: theme.palette.text.secondary }}></MenuIcon>
          </IconButton>
        </Grid>
      </Grid>
      <AccountMenu
        themeMode={themeMode}
        toggleMode={toggleMode}
        anchorEl={anchorEl}
        isAccountMenuOpen={isAccountMenuOpen}
        handleProfile={handleProfile}
        handleLogout={handleLogout}
        handleChangePassword={handleChangePassword}
        onClose={handleAccountMenuClose}
      />
      <CustomDrawer
        themeMode={themeMode}
        toggleMode={toggleMode}
        toggleDrawer={toggleDrawer}
        handleLogout={handleLogout}
        handleProfile={handleProfile}
        handleChangePassword={handleChangePassword}
        drawerState={drawerState}
      />
    </React.Fragment>
  );
};

export default TopMenu;
