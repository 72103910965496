import {
  ButtonProps,
  Button,
  IconButton,
  Box,
  Tab,
  Tabs,
  FormLabelProps,
  FormLabel,
} from '@mui/material';
import { FC } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { formatDate } from '@utils/date';
import { DATE_FORMAT_WITHOUT_YEAR } from '@constants/date';

interface TournamentsDatesProps {
  startDate: Date | string;
  endDate: Date | string;
}

interface TransactionTypographyProps {
  isSuccess?: boolean;
}

export const PrimaryButton: FC<ButtonProps> = (props) => (
  <Button variant="contained" color={'primary'} fullWidth {...props} />
);

export const SecondaryButton: FC<ButtonProps> = (props) => (
  <Button variant="outlined" color="inherit" fullWidth {...props} />
);

export const ErrorLabel: FC<FormLabelProps> = (props) => (
  <FormLabel sx={{ color: (theme) => theme.palette.error.main }} {...props} />
);

export const NoneLabel = styled('span')`
  font-size: 0.7em;
  margin-left: 10px;
  margin-right: 5px;
`;

export const InfoLabel = styled('span')`
  font-size: 0.7em;
  font-style: italic;
`;

export const CartDrawer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
  paddingLeft: 0,
  paddingRight: 0,
}));

export const DrawerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: theme.spacing(72.5),
  width: '85%',
}));

export const TransactionBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey['800'],
  padding: theme.spacing(4, 2),
  borderRadius: theme.spacing(1),
  gap: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
}));

export const TournamentsDates = ({ startDate, endDate }: TournamentsDatesProps) => {
  const theme = useTheme();
  return (
    <Typography sx={{ width: theme.spacing(18) }} fontSize="0.9rem" pl={1}>
      {formatDate(startDate, DATE_FORMAT_WITHOUT_YEAR)} -{' '}
      {formatDate(endDate, DATE_FORMAT_WITHOUT_YEAR)}
    </Typography>
  );
};

export const TournamentsIconButton = styled(IconButton)(({ theme }) => ({
  position: 'static',
  zIndex: 10,
  display: 'flex',
  width: theme.spacing(8),
  height: theme.spacing(8),
  color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
}));

export const StyledInfoBox = styled(Box)(({ theme }) => ({
  gap: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledPriceBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.main}`,
  height: theme.spacing(8),
  alignItems: 'center',
  display: 'flex',
  padding: theme.spacing(0, 2, 0, 6),
  justifyContent: 'space-between',
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTab-root.Mui-selected': {
    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
    fontWeight: 700,
    textDecoration: 'underline',
    textDecorationThickness: theme.spacing(0.25),
    textUnderlineOffset: theme.spacing(0.5),
  },
}));

export const StyledTab = styled(Tab)(() => ({
  textTransform: 'capitalize',
}));

export const BoldTypography = styled(Typography)(() => ({
  fontWeight: 700,
}));

export const SemiBoldTypography = styled(Typography)(() => ({
  fontWeight: 500,
}));

export const ItalicTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontStyle: 'italic',
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  fontSize: theme.spacing(1.75),
}));

export const NormalBoldTypography = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
  fontSize: theme.spacing(1.75),
  fontWeight: 700,
}));

export const ItalicBoldTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
  fontStyle: 'italic',
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  fontSize: theme.spacing(1.75),
  fontWeight: 700,
}));

export const NormalSemiBoldTypography = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
  fontSize: theme.spacing(1.75),
  fontWeight: 500,
}));

export const TransactionMainTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isSuccess',
})<TransactionTypographyProps>(({ isSuccess = false, theme }) => ({
  textTransform: 'uppercase',
  textAlign: 'center',
  fontSize: theme.spacing(3),
  fontWeight: 700,
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(0.5),
  color: isSuccess ? theme.palette.success.main : theme.palette.error.dark,
}));
