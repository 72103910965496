import { TournamentType } from '@typings/tournaments.types';
import { TournamentsIconButton } from '@components/common/FormStyledComponents';
import { useTheme } from '@mui/material/styles';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { SxProps } from '@mui/material';

interface TournamentsCheckoutActionsProps {
  selectedTournaments: [] | number[];
  checkoutAction: (tournament: TournamentType) => void;
  tournament: TournamentType;
}

const TournamentsCheckoutActions = ({
  checkoutAction,
  selectedTournaments,
  tournament,
}: TournamentsCheckoutActionsProps) => {
  const theme = useTheme();
  const sizesProps: SxProps = { height: theme.spacing(4.75), width: theme.spacing(4.75) };

  return (
    <TournamentsIconButton
      aria-label="add"
      onClick={() => {
        checkoutAction(tournament);
      }}
      disableRipple
    >
      {selectedTournaments.find((selected) => selected === tournament.tournamentId) ? (
        <DeleteForeverSharpIcon sx={sizesProps} />
      ) : (
        <AddCircleRoundedIcon sx={sizesProps} />
      )}
    </TournamentsIconButton>
  );
};

export default TournamentsCheckoutActions;
