import useSWR from 'swr';
import { ItemBooleanFetcher } from '@services/index';
import { TournamentsType } from '@typings/tournaments.types';
import { tournamentsApi } from '@services/api/tournaments';

const listFetcher: ItemBooleanFetcher<TournamentsType> = async (
  includeEntered: boolean,
  includeAvailable: boolean
) => await tournamentsApi.getTournaments(includeEntered, includeAvailable);

export function useTournaments(includeEntered: boolean, includeAvailable: boolean) {
  const { data, error, mutate } = useSWR(
    [includeEntered, includeAvailable, 'get-tournaments'],
    listFetcher
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
}
