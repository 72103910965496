import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useMessages } from '@services/api/useMessages';
import MessageField from '@components/Account/MessageField';

export const ImportantMessages = () => {
  const { data, isLoading } = useMessages();
  const messages = data?.data ?? [];
  const hasMessages = !isLoading && messages.length > 0;

  if (!hasMessages) {
    return null;
  }

  return (
    <Card>
      <CardContent>
        <Typography
          variant="subtitle1"
          component="div"
          color="primary"
          sx={{ fontWeight: '500', mb: 1 }}
        >
          IMPORTANT MESSAGE
        </Typography>
        {messages.map((message, index) => (
          <MessageField key={index} message={message} showDivider={index !== messages.length - 1} />
        ))}
      </CardContent>
    </Card>
  );
};

export default ImportantMessages;
