import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { styled } from '@mui/material/styles';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Accordion, AccordionSummary } from '@mui/material';

interface StripedAccordionProps {
  index: number;
}

export const accordionProps = {
  expandIcon: (
    <ExpandMoreIcon
      sx={{
        pointerEvents: 'auto',
      }}
    />
  ),
};

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey['300'] : theme.palette.grey['50'],
  color: theme.palette.common.black,
  alignItems: 'flex-start',
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  gap: theme.spacing(4),
  padding: theme.spacing(2, 2, 3, 6.25),
}));

export const StripedAccordion = styled(Accordion, {
  shouldForwardProp: (prop) => prop !== 'index',
})<StripedAccordionProps>(({ index, theme }) => ({
  backgroundColor:
    index % 2 === 0 && theme.palette.mode === 'dark'
      ? theme.palette.grey['900']
      : index % 2 !== 0 && theme.palette.mode === 'dark'
      ? theme.palette.grey['800']
      : theme.palette.mode === 'light' && index % 2 === 0
      ? theme.palette.grey['300']
      : theme.palette.grey['100'],
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  flexDirection: 'row-reverse',
  height: '5em',
  paddingRight: theme.spacing(1),
}));
