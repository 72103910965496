import { request } from '@services/request';
import { API_PATH } from '@constants/api';
import { ApiMethod } from '@services/api/index';
import { MembershipStatusType } from '@typings/seasons.types';

const getSeasonMembershipStatus = (): Promise<MembershipStatusType> => request(`${API_PATH.seasons}/membership-status`, ApiMethod.GET);

export const seasonsApi = {
  getSeasonMembershipStatus,
};
