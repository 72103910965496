import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

interface DayBoxProps {
  isPreviousDay: boolean;
  isActiveDay: boolean;
}

export const TournamentNameBox = styled(Box)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey['900'] : theme.palette.grey['300'],
  fontWeight: 700,
  fontSize: '1.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
}));

export const DaysBoxesWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  gap: theme.spacing(0.75),
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(3, 0),
  left: theme.spacing(2),
  overflowY: 'auto',
}));

export const DayBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isPreviousDay' && prop !== 'isActiveDay',
})<DayBoxProps>(({ isPreviousDay, isActiveDay, theme }) => ({
  textTransform: 'uppercase',
  border: `${theme.spacing(0.25)} solid ${
    isPreviousDay ? theme.palette.primary.dark : theme.palette.primary.main
  }`,
  height: '78px',
  width: theme.spacing(6),
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  fontWeight: 700,
  color: isPreviousDay ? theme.palette.text.secondary : theme.palette.text.primary,
  cursor: isPreviousDay ? 'initial' : 'pointer',
  textAlign: 'center',
  backgroundColor: isActiveDay ? theme.palette.primary.main : 'transparent',
  padding: theme.spacing(1),
}));
