import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import { LogoBox } from '@components/layout/LogoBox/LogoBox';

interface TitleProps {
  title: string;
  subTitle?: string;
  showLogo?: boolean;
}

export const Title: FC<TitleProps> = ({ title, subTitle, showLogo }) => {
  return (
    <>
      {showLogo && <LogoBox />}
      <Typography
        variant="h5"
        sx={{
          mt: 3,
          mb: 3,
          fontWeight: 500,
        }}
      >
        {title}
      </Typography>
      {subTitle && (
        <Typography variant="body1" color="textSecondary" sx={{ mb: 4.5 }}>
          {subTitle}
        </Typography>
      )}
    </>
  );
};
