import useSWR from 'swr';
import { UserType } from '@typings/account.types';
import { accountsApi } from '@services/api/accounts';
import { ItemFetcher } from '@services/index';

const itemFetcher: ItemFetcher<UserType> = async () => await accountsApi.getProfile();

export function useProfile() {
  const { data, error, mutate } = useSWR(['get-profile'], itemFetcher);

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
}
