import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import CheckoutAccordion from '@components/Checkout/CheckoutAccordion';
import CheckoutForm from '@components/Checkout/CheckoutForm';
import { checkoutEmptyValues, checkoutTournamentTerms } from '@constants/checkout';
import { checkoutApi } from '@services/api/checkout';
import { useCheckout } from '@services/api/useCheckout';
import { ApiError } from '@services/apiError';
import { TournamentCheckoutType } from '@typings/checkout.types';
import { TournamentCheckoutValidationSchema } from '@validations/CheckoutValidationSchema';

const TournamentCheckout = () => {
  const [searchParams] = useSearchParams();
  const selectedTournaments = searchParams.get('selectedTournaments')
    ? (searchParams.get('selectedTournaments') as string).split(',')
    : [''];
  const [tournamentsIds, setTournamentsIds] = useState<string[]>(selectedTournaments);
  const { data } = useCheckout(tournamentsIds);

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (values: TournamentCheckoutType, actions: FormikHelpers<TournamentCheckoutType>) => {
    try {
      const response = await checkoutApi.postCheckoutTournaments(values);
      actions.resetForm();
      window.location.href = response.nextUrl;
    } catch (e) {
      return enqueueSnackbar((e as ApiError).title, { variant: 'error' });
    }
  };

  const formik = useFormik<TournamentCheckoutType>({
    initialValues: {
      ...checkoutEmptyValues,
      ...data,
      termsAndConditionsAccepted: false,
      playersContractAccepted: false,
      tournamentIds: tournamentsIds.map((i) => Number(i)),
      emailCc: '',
    },
    validationSchema: TournamentCheckoutValidationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <>
      {data && <CheckoutAccordion data={data} setTournamentsIds={setTournamentsIds} />}
      <CheckoutForm terms={checkoutTournamentTerms} formik={formik} />
    </>
  );
};

export default TournamentCheckout;
