import { Navigate } from 'react-router-dom';

import PaymentsFailure from '@/pages/payments/failure';
import PaymentsSuccess from '@/pages/payments/success';

import { APP_PATHS } from './constants';

const { PAYMENTS } = APP_PATHS;

export const PaymentsRoutes = [
  {
    path: '',
    exact: true,
    element: <Navigate to="/" />
  },
  {
    path: PAYMENTS.FAILURE,
    element: <PaymentsFailure />,
    handle: { title: 'Payment Failure'},
  },
  {
    path: PAYMENTS.SUCCESS,
    element: <PaymentsSuccess />,
    handle: { title: 'Payment Success'},
  },
];
