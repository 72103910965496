import useSWR from 'swr';
import { ItemValueFetcher } from '@services/index';
import { practiceTableDaysApi } from '@services/api/practice';
import { PracticeDaysType } from '@typings/practice.types';

const listFetcher: ItemValueFetcher<PracticeDaysType> = async (id) =>
  await practiceTableDaysApi.getPracticeTableDays(id);

export function usePracticeTableDays(tournamentId: string) {
  const { data, error, mutate } = useSWR([tournamentId, 'get-tournaments'], listFetcher);

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
}
