import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import React, { FC } from 'react';

interface MessageFieldProps {
  message: string;
  showDivider: boolean;
}

interface DisclaimerMessageProps {
  message: string;
}

const MessageField: FC<MessageFieldProps> = ({ message, showDivider }) => {
  return (
    <>
      <Typography sx={{}} variant="body2">
        {message}
      </Typography>
      {showDivider && <Divider sx={{ mt: 1, mb: 1 }} />}
    </>
  );
};

const DisclaimerMessages: FC<DisclaimerMessageProps> = ({ message }) => {
  return (
    <Card>
      <CardContent>
        <Typography
          variant="subtitle1"
          component="div"
          color="primary"
          sx={{ fontWeight: '500', mb: 1 }}
        >
          DISCLAIMER
        </Typography>
        <MessageField message={message} showDivider={false} />
      </CardContent>
    </Card>
  );
};

export default DisclaimerMessages;
