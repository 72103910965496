import useSWR from 'swr';
import { ItemMultiValueFetcher } from '@services/index';
import { practiceTableDaysApi } from '@services/api/practice';
import { PracticeTableBlocksType } from '@typings/practice.types';

const listFetcher: ItemMultiValueFetcher<PracticeTableBlocksType> = async (tournamentId, dayId) =>
  await practiceTableDaysApi.getPracticeTableBlocks(tournamentId, dayId);

export function usePracticeTableBlocks(
  tournamentId: string,
  dayId: number | undefined
) {
  const { data, error, mutate } = useSWR(
    [tournamentId, dayId, 'get-practice-table-blocks'],
    listFetcher
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
}
