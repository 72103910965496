import useSWR from 'swr';
import { countriesApi } from '@services/api/countries';

const itemFetcher = (query: string) => countriesApi.getCountries(query);

export function useCountries(query = '') {
  const { data, error, mutate } = useSWR(['get-countries'], () => itemFetcher(query));

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
}
