import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PasswordRequirements from '@components/Account/PasswordRequirements';
import { CartDrawer, DrawerBox, PrimaryButton } from '@components/common/FormStyledComponents';
import { Field } from '@components/layout/Field/Field';
import { Title } from '@components/layout/Title/Title';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { accountsApi, ClientType } from '@services/api/accounts';
import { isApiError } from '@services/apiError';
import { ResetPasswordType } from '@typings/account.types';
import { isString } from '@utils/typeGuards';
import ResetPasswordValidationSchema from '@validations/ResetPasswordValidationSchema';
import { APP_PATHS } from '@/router/constants';
import { useEffect } from 'react';

const ResetPassword = () => {
  const invalidTokenErrorCodes = ['409-012', '404-002', '404-003'];
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const hasPasswordResetToken = token && isString(token);

  useEffect(() => {
    if (!hasPasswordResetToken) {
      navigate(APP_PATHS.ACCOUNT.LOGIN);
    } else {
      validateToken(token as string);
    }
  }, []);

  const validateToken = async (token: string) => {
    try {
      await accountsApi.validatePasswordResetToken({ token });
    } catch (e) {
      showApiErrorMessage(e);
      navigate(APP_PATHS.ACCOUNT.FORGOT_PASSWORD);
    }
  };

  const showApiErrorMessage = (e: any) => {
    let snackBarMessage = (e as Error).message;
    if (isApiError(e)) {
      if (e.code && invalidTokenErrorCodes.includes(e.code)) {
        snackBarMessage = 'Token has expired or is not valid. Please request password reset again.';
      } else {
        snackBarMessage = e.title;
      }
    }
    enqueueSnackbar(snackBarMessage, { variant: 'error', autoHideDuration: 7000 }); 
  };

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
      clientType: ClientType.PLAYER_PORTAL,
    },

    validationSchema: ResetPasswordValidationSchema,

    onSubmit: async (values) => {
      const model: ResetPasswordType = {
        token: token as string,
        password: values.newPassword,
        clientType: values.clientType,
      };

      try {
        await accountsApi.resetPassword(model);
        formik.resetForm();
        enqueueSnackbar('Your password has been successfully reset.', { variant: 'success' });
        return navigate(APP_PATHS.ACCOUNT.LOGIN);
      } catch (e) {
        showApiErrorMessage(e);
      }
    },
  });

  if (!hasPasswordResetToken) {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <CartDrawer>
      <DrawerBox>
        <Title 
          title="Password reset" 
          subTitle="Please set a new password for your WSL Tournament Entry System account." 
          showLogo />
        <PasswordRequirements />
        <Box sx={{ mb: 2, mt: 6, display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Field label="New password *" name="newPassword" formik={formik} type="password" />
          <Field
            label="Confirm new password *"
            name="confirmPassword"
            formik={formik}
            type="password"
          />
          <PrimaryButton
            disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
            sx={{ mt: 4, mb: 1 }}
            onClick={() => {
              formik.submitForm();
            }}
          >
            Change password
          </PrimaryButton>
        </Box>
      </DrawerBox>
    </CartDrawer>
  );
};

export default ResetPassword;
