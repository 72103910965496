import { useTheme } from '@mui/material/styles';
import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from '@mui/system';

const TransactionLoader = () => {
  const theme = useTheme();

  return (
    <Box marginTop={theme.spacing(8)}>
      <LinearProgress />
    </Box>
  );
};

export default TransactionLoader;
