import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import {
  StyledTournamentsTableHeaderCell,
  StyledTournamentsTableRow,
  StyledTournamentsTableCell,
} from '@components/Tournaments/TournamentsTable/TournamentsTableStyledComponents';
import { formatDate } from '@utils/date';
import { DATE_FORMAT } from '@constants/date';
import { TournamentType } from '@typings/tournaments.types';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CheckoutFooter from '@components/Tournaments/CheckoutFooter';
import TournamentsCheckoutActions from '@components/Tournaments/TournamentsAccordion/TournamentsAccordionActions';
import EntryPackIcon from '@components/Tournaments/EntryPackIcon';
import Typography from '@mui/material/Typography';

interface TournamentsListProps {
  tournaments: TournamentType[];
  bookPractice?: {
    handleBooking: (tournamentId: number, tournamentName: string) => void;
    checkIsTournamentFinished: (day: string) => boolean;
  };
  checkout?: {
    selectedTournaments: [] | number[];
    checkoutAction: (tournament: TournamentType) => void;
    tournamentsPrice: number;
    handleCheckout: VoidFunction;
  };
}

const TournamentsTable = ({ tournaments, bookPractice, checkout }: TournamentsListProps) => {
  const theme = useTheme();

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: theme.breakpoints.up('sm') }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTournamentsTableHeaderCell>Name</StyledTournamentsTableHeaderCell>
              <StyledTournamentsTableHeaderCell>Venue</StyledTournamentsTableHeaderCell>
              <StyledTournamentsTableHeaderCell>Start</StyledTournamentsTableHeaderCell>
              <StyledTournamentsTableHeaderCell>End</StyledTournamentsTableHeaderCell>
              <StyledTournamentsTableHeaderCell>Closing date</StyledTournamentsTableHeaderCell>
              <StyledTournamentsTableHeaderCell>Fee</StyledTournamentsTableHeaderCell>
              <StyledTournamentsTableHeaderCell>Entry pack</StyledTournamentsTableHeaderCell>
              {bookPractice && <StyledTournamentsTableHeaderCell />}
              {checkout && <StyledTournamentsTableHeaderCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {tournaments.map((row, index) => (
              <StyledTournamentsTableRow key={index}>
                <StyledTournamentsTableCell component="th" scope="row">
                  <Typography
                    fontSize="0.9rem"
                    pl={1}
                    sx={{
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {row.name}
                  </Typography>
                </StyledTournamentsTableCell>
                <StyledTournamentsTableCell>{row.venueName}</StyledTournamentsTableCell>
                <StyledTournamentsTableCell>
                  {formatDate(row.startsOn, DATE_FORMAT)}
                </StyledTournamentsTableCell>
                <StyledTournamentsTableCell>
                  {formatDate(row.endsOn, DATE_FORMAT)}
                </StyledTournamentsTableCell>
                <StyledTournamentsTableCell>
                  {formatDate(row.entryDeadline, DATE_FORMAT)}
                </StyledTournamentsTableCell>
                <StyledTournamentsTableCell>£{row.entryFee}</StyledTournamentsTableCell>
                <StyledTournamentsTableCell>
                  {row.isEntryPack ? (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <EntryPackIcon fileName={row.name} tournamentId={row.tournamentId} />
                    </div>
                  ) : null}
                </StyledTournamentsTableCell>
                {bookPractice && (
                  <StyledTournamentsTableCell>
                    {bookPractice.checkIsTournamentFinished(row.endsOn) && !row.isPlayerKnockedOut ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => bookPractice.handleBooking(row.tournamentId, row.name)}
                      >
                        Book Practice
                      </Button>
                    ) : null}
                  </StyledTournamentsTableCell>
                )}
                {checkout && (
                  <StyledTournamentsTableCell>
                    <TournamentsCheckoutActions
                      selectedTournaments={checkout.selectedTournaments}
                      checkoutAction={checkout.checkoutAction}
                      tournament={row}
                    />
                  </StyledTournamentsTableCell>
                )}
              </StyledTournamentsTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {checkout && checkout.selectedTournaments.length > 0 && checkout.tournamentsPrice >= 0 && (
        <CheckoutFooter
          selectedTournaments={checkout.selectedTournaments}
          tournamentsPrice={checkout.tournamentsPrice}
          handleCheckout={checkout.handleCheckout}
        />
      )}
    </>
  );
};

export default TournamentsTable;
