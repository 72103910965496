import { createTheme, ThemeOptions } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { wstBlack, wstCommonThemeOptions, wstRed } from './wstTheme';

export const wstLightThemeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    background: {
      default: '#ffffff',
    },
    text: {
      primary: wstBlack,
    },
  },
  header: {
    background: '#ffffff',
    text: wstRed,
    divider: true,
  },
  resources: {
    logo: {
      src: '/images/wst-logo-no-padding.svg',
      background: '#ffffff',
    },
  },
};

export const wstLightTheme = createTheme(deepmerge(wstLightThemeOptions, wstCommonThemeOptions));
