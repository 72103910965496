import Practice from '@/pages/practice';

export const PracticeRoutes = [
  {
    path: '',
    element: <Practice />,
    exact: true,
    handle: { title: 'Practice', isMarginTop: false },
  },
];
