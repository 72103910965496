import { styled } from '@mui/material/styles';
import { Box, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { BookingStatusType } from '@typings/practice.types';

interface StripedTimeTableCellProps {
  index: number;
}

interface TableBoxProps {
  status: BookingStatusType;
}

export const StyledTableContainer = styled(TableContainer)(() => ({}));

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.grey['300'],
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.grey['400'],
  },
}));

export const StripedTimeTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'index',
})<StripedTimeTableCellProps>(({ index, theme }) => ({
  textAlign: 'center',
  width: 'fit-content',
  backgroundColor: index % 2 === 0 ? theme.palette.primary.dark : theme.palette.primary.main,
  border: 'none',
  padding: theme.spacing(2, 0),
}));

export const PracticeTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0.5, 0, 0.5, 0.5),
  border: 'none',
  width: 'fit-content',
}));

export const TableBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'status',
})<TableBoxProps>(({ status, theme }) => ({
  backgroundColor:
    status === 'Available'
      ? theme.palette.common.white
      : status === 'BookedByCurrentUser'
      ? theme.palette.success.main
      : theme.palette.grey['500'],
  boxShadow:
    status === 'Available' || status === 'BookedByCurrentUser'
      ? `${theme.spacing(0.3)} ${theme.spacing(0.3)} ${theme.spacing(0.3)} ${
          theme.palette.grey['500']
        }`
      : 'none',
  width: theme.spacing(4.5),
  height: theme.spacing(3.5),
  cursor: status === 'BookedByCurrentUser' || status === 'Available' ? 'pointer' : 'initial',
  color: theme.palette.grey['700'],
  borderRadius: theme.spacing(0.5),
  display: 'flex',
  margin: 'auto',
}));
