import { Contact as ContactModel } from '@typings/Contact';
import ContactDetails from '@components/Contact/ContactDetails';
import Grid from '@mui/material/Grid';

const Contact = () => {
  const office: ContactModel = {
    name: 'By Post & Phone',
    phone: '+441173178200',
    phoneFormatted: '+44 (0)117 3178200',
    address: [
      'World Snooker Ltd,',
      '75 Whiteladies Road,',
      'Clifton,',
      'Bristol,',
      'BS8 2NT,',
      'United Kingdom',
    ],
  };
  const contacts: ContactModel[] = [
    {
      name: 'Suzanne Goscombe',
      jobDescription: 'Operations Manager',
      email: 'suzanne.goscombe@wst.tv',
    },
    {
      name: 'Natasha Gardiner',
      jobDescription: 'Accounts',
      email: 'natasha.gardiner@wst.tv',
    },
    {
      name: 'Simon Brownell',
      jobDescription: 'Chief Executive Officer',
      email: 'simon.brownell@wst.tv',
    },
  ];

  return (
    <Grid
      container
      spacing={3}
      sx={{
        pl: { xs: 3, lg: 0 },
        pr: { xs: 3, lg: 0 },
      }}
    >
      <Grid item xs={12} sm={6}>
        <ContactDetails contact={office} />
      </Grid>
      <Grid item container xs={12} sm={6} spacing={3}>
        {contacts.map((contact, index) => (
          <Grid key={index} item xs={12}>
            <ContactDetails contact={contact} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Contact;
