import useSWR from 'swr';
import { ItemValueFetcher } from '@services/index';
import { EntryPackType } from '@typings/tournaments.types';
import { tournamentsApi } from '@services/api/tournaments';

const listFetcher: ItemValueFetcher<EntryPackType> = async (id) =>
  await tournamentsApi.getTournamentPack(id);

export function useTournamentEntryPack(id: number) {
  const { data, error, mutate } = useSWR([id, 'get-tournaments-entry-pack'], listFetcher);

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
}
