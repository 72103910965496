import React, { FC } from 'react';
import { Box, Modal } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  BoldTypography,
  PrimaryButton,
  SecondaryButton,
} from '@components/common/FormStyledComponents';
import { useTheme } from '@mui/material/styles';

interface RemoveTournamentModalProps {
  open: boolean;
  handleClose: VoidFunction;
  handleRemove: VoidFunction;
  tournamentName: string;
}

const RemoveTournamentModal: FC<RemoveTournamentModalProps> = ({
  open,
  handleClose,
  handleRemove,
  tournamentName,
}) => {
  const theme = useTheme();
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          gap: theme.spacing(4),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <BoldTypography variant="h4" textAlign="center">
          {tournamentName}
        </BoldTypography>
        <Typography textAlign="center">
          Are you sure you want to remove this event from the list?
        </Typography>
        <Box
          sx={{
            gap: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <PrimaryButton onClick={handleRemove}>Remove</PrimaryButton>
          <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default RemoveTournamentModal;
