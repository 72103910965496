import { request } from '@services/request';
import { API_PATH } from '@constants/api';
import { ApiMethod } from '@services/api/index';
import { SponsorsType } from '@typings/Sponsor';

const getSponsors = async (): Promise<SponsorsType> => {
  return await request(`${API_PATH.sponsors}`, ApiMethod.GET);
};

export const sponsorsApi = {
  getSponsors,
};
