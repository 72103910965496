import { request } from '@services/request';
import { API_PATH } from '@constants/api';
import { ApiMethod } from '@services/api/index';
import { CountriesType } from '@typings/country.types';

const getCountries = (query: string): Promise<CountriesType> => request(`${API_PATH.countries}${query}`, ApiMethod.GET);

export const countriesApi = {
  getCountries,
};
