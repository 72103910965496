import * as yup from 'yup';
import passwordValidationSchema from '@validations/PasswordValidationSchema';

const ChangePasswordValidationSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .required('Enter your old password'),
  newPassword: passwordValidationSchema
    .notOneOf([yup.ref('oldPassword'), null], 'Yours new password same as old password')
    .required('Enter your new password'),
  confirmPassword: passwordValidationSchema
    .oneOf([yup.ref('newPassword'), null], 'Password entries do not match')
    .required('Confirm your new password'),
});

export default ChangePasswordValidationSchema;
