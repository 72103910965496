import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  BoldTypography,
  SemiBoldTypography,
  StyledInfoBox,
  StyledPriceBox,
  TournamentsDates,
  TournamentsIconButton,
} from '@components/common/FormStyledComponents';
import { TournamentType } from '@typings/tournaments.types';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import { formatDate } from '@utils/date';
import { DATE_FORMAT } from '@constants/date';
import { CheckoutTournamentsType } from '@typings/checkout.types';
import { useTheme } from '@mui/material/styles';
import RemoveTournamentModal from '@components/Checkout/RemoveTournamentModal';
import {
  accordionProps,
  StripedAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from '@components/Accordion/StyledAccordion';
import EntryPackIcon from '@components/Tournaments/EntryPackIcon';

interface CheckoutAccordionProps {
  data: CheckoutTournamentsType;
  setTournamentsIds: Dispatch<SetStateAction<string[]>>;
}

const CheckoutAccordion: FC<CheckoutAccordionProps> = ({ data, setTournamentsIds }) => {
  const theme = useTheme();
  const [selectedTournament, setSelectedTournament] = useState<TournamentType>();

  const handleOpen = (tournament: TournamentType) => setSelectedTournament(tournament);
  const handleClose = () => setSelectedTournament(undefined);
  const handleRemoveTournament = (tournament: TournamentType) => {
    setTournamentsIds((ids) => ids.filter((id) => id !== tournament.tournamentId.toString()));
    handleClose();
  };

  return (
    <>
      {data.tournaments?.map((item: TournamentType, index) => (
        <Box key={item.tournamentId}>
          <StripedAccordion square index={index}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <StyledAccordionSummary
                sx={{
                  width: '100%',
                  pl: '8px',
                  overflow: 'hidden',
                }}
                {...accordionProps}
              >
                <BoldTypography
                  fontSize="0.9rem"
                  pl={1}
                  sx={{
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {item.name}
                </BoldTypography>
              </StyledAccordionSummary>

              <TournamentsDates startDate={item.startsOn} endDate={item.endsOn} />

              {data.tournaments?.length > 1 && (
                <TournamentsIconButton
                  aria-label="delete"
                  onClick={() => {
                    handleOpen(item);
                  }}
                >
                  <DeleteForeverSharpIcon
                    sx={{
                      width: theme.spacing(4.75),
                      height: theme.spacing(4.75),
                    }}
                  />
                </TournamentsIconButton>
              )}
            </Box>

            <StyledAccordionDetails>
              <StyledInfoBox>
                {item?.venueName && <BoldTypography>Venue:</BoldTypography>}
                <BoldTypography>Start:</BoldTypography>
                <BoldTypography>End:</BoldTypography>
                <BoldTypography>Closing date:</BoldTypography>
                <BoldTypography>Entry fee:</BoldTypography>
                {item.isEntryPack && <BoldTypography>Entry pack:</BoldTypography>}
              </StyledInfoBox>
              <StyledInfoBox>
                {item?.venueName && <Typography>{item.venueName}</Typography>}
                <Typography>{formatDate(item.startsOn, DATE_FORMAT)}</Typography>
                <Typography>{formatDate(item.endsOn, DATE_FORMAT)}</Typography>
                <Typography>{formatDate(item.entryDeadline, DATE_FORMAT)}</Typography>
                <SemiBoldTypography>£{item.entryFee}</SemiBoldTypography>
                {item.isEntryPack && (
                  <EntryPackIcon fileName={item?.name} tournamentId={item.tournamentId} />
                )}
              </StyledInfoBox>
            </StyledAccordionDetails>
          </StripedAccordion>
        </Box>
      ))}
      {data.tournaments && (
        <StyledPriceBox>
          <Typography color="secondary">
            TOTAL ({data.tournaments.length} event
            {data.tournaments.length > 1 ? 's' : ''})
          </Typography>
          <Typography color="secondary">{data.totalCost}£</Typography>
        </StyledPriceBox>
      )}
      {selectedTournament && (
        <RemoveTournamentModal
          open={selectedTournament !== undefined}
          handleClose={handleClose}
          handleRemove={() => handleRemoveTournament(selectedTournament)}
          tournamentName={selectedTournament.name}
        />
      )}
    </>
  );
};

export default CheckoutAccordion;
