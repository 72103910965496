import { useTheme } from '@mui/material/styles';
import { useTournamentEntryPack } from '@services/api/useTournamentPack';
import { tournamentsApi } from '@services/api/tournaments';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

interface EntryPackIconProps {
  tournamentId: number;
  fileName: string;
}

const EntryPackIcon = ({ tournamentId, fileName }: EntryPackIconProps) => {
  const theme = useTheme();
  const { data: entryPack } = useTournamentEntryPack(tournamentId);

  function Download() {
    tournamentsApi
      .getTournamentPack(tournamentId)
      .then((response) => {
        return response.blob();
      })
      .then((data) => {
        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(data);
        a.download = `${fileName}`;
        a.click();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const iconProps = {
    maxWidth: 32,
    color: theme.palette.error.dark,
  };

  if (!entryPack) {
    return null;
  }

  return (
    <ListItemButton
      sx={{
        textAlign: 'center',
        justifyContent: 'center',
        width: 'auto',
        maxWidth: 32,
        p: 1,
      }}
      onClick={() => {
        Download();
      }}
    >
      <ListItemIcon sx={{ minWidth: 32 }}>
        <img src={'/images/pdf-download.svg'} alt='PDF download' style={iconProps} />
      </ListItemIcon>
    </ListItemButton>
  );
};

export default EntryPackIcon;
