import { SponsorType } from '@typings/Sponsor';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

interface SponsorsListProps {
  sponsors: SponsorType[];
}

interface SponsorCardFieldProps {
  title: string;
  value: string;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.primary.main,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const SponsorsTable = ({ sponsors }: SponsorsListProps) => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell>NAME</StyledTableCell>
            <StyledTableCell>TYPE</StyledTableCell>
            <StyledTableCell>SIZE</StyledTableCell>
            <StyledTableCell>COLOUR</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sponsors.map((row, index) => (
            <StyledTableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell>{row.type}</TableCell>
              <TableCell>{row.size}</TableCell>
              <TableCell>{row.colour}</TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const SponsorCardField: FC<SponsorCardFieldProps> = ({ title, value }) => {
  return (
    <>
      <Typography variant="subtitle2" color="primary" sx={{ mt: 1 }}>
        {title}
      </Typography>
      <Typography variant="body1">{value}</Typography>
    </>
  );
};

const SponsorsCards = ({ sponsors }: SponsorsListProps) => {
  return (
    <Grid
      container
      spacing={3}
      wrap="wrap"
      sx={{
        pl: { xs: 3, lg: 0 },
        pr: { xs: 3, lg: 0 },
      }}
    >
      {sponsors.map((row, index) => (
        <Grid key={index} item xs={12} sm={6}>
          <Card>
            <CardContent>
              <SponsorCardField title="NAME" value={row.name} />
              <SponsorCardField title="TYPE" value={row.type} />
              <SponsorCardField title="SIZE" value={row.size} />
              <SponsorCardField title="COLOUR" value={row.colour} />
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

const SponsorsList = ({ sponsors }: SponsorsListProps): JSX.Element => {
  const useCards = useMediaQuery('(max-width:700px)');

  if (useCards) return SponsorsCards({ sponsors });

  return SponsorsTable({ sponsors });
};

export default SponsorsList;
