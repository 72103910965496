import { ThemeOptions } from '@mui/material/styles';

export const wstBlack = '#000000';
export const wstRed = '#ce132d';
export const wstYellow = '#F2A900';
export const wstGreen = '#4CBB17';
export const wstPink = '#FF585D';
export const wstBlue = '#006BA6';
export const wstGold = '#85764E';
export const wstBrown = '#4F2C1D';
export const wstLightGrey = '#eeeeee';
export const wstDarkGrey = '#131313';
export const wstDarkGreyPaper = '#191919';

declare module '@mui/material/styles' {
  interface Theme {
    header: {
      background: string;
      text: string;
      divider: boolean;
    };
    resources: {
      logo: {
        src: string;
        background: string;
      };
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    header?: {
      background?: string;
      text?: string;
      divider?: boolean;
    };
    resources?: {
      logo?: {
        src?: string;
        background?: string;
      };
    };
  }
}

export const wstCommonThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: wstRed,
    },
    secondary: {
      main: wstYellow,
    },
    success: {
      main: wstGreen,
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 680,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
};
