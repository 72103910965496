import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { APP_PATHS } from '@/router/constants';
import PasswordRequirements from '@components/Account/PasswordRequirements';
import { CartDrawer, DrawerBox, PrimaryButton } from '@components/common/FormStyledComponents';
import { Field } from '@components/layout/Field/Field';
import { Title } from '@components/layout/Title/Title';
import { AuthContext, AuthContextValue } from '@contexts/auth';
import { setLoginData } from '@contexts/auth/reducer/actions';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MuiLink from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import { accountsApi, ClientType } from '@services/api/accounts';
import { ApiError, isApiError } from '@services/apiError';
import { signIn, signOut } from '@services/authorization';
import { ChangePasswordType } from '@typings/account.types';
import ChangePasswordValidationSchema from '@validations/ChangePasswordValidationSchema';

const SecurityUpdate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const navigate = useNavigate();
  const [isSigningIn, setIsSigningIn] = useState(false);

  const {
    state: { userName, passwordChangeToken },
    dispatch
  } = useContext<AuthContextValue>(AuthContext);

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      clientType: ClientType.PLAYER_PORTAL,
    },

    validationSchema: ChangePasswordValidationSchema,

    onSubmit: async (values) => {
      const model: Omit<ChangePasswordType, 'id'> = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
        clientType: values.clientType,
      };

      try {
        await accountsApi.changePasswordUsingToken(model, passwordChangeToken);
        formik.resetForm();
        enqueueSnackbar('The password has been changed.', { variant: 'success' });
        return await signInWithNewPassword(userName, model.newPassword);
      } catch (e) {
        if (isApiError(e)) {
          return enqueueSnackbar((e as ApiError).title, { variant: 'error' });
        } else {
          return enqueueSnackbar((e as Error).message, { variant: 'error' });
        }        
      }
    },
  });

  const signInWithNewPassword = async (username: string, password: string) => {
    setIsSigningIn(true);

    signOut();
    const response = await signIn({ username, password });

    if (response?.error) {
      enqueueSnackbar(`Could not log in. ${response.error}`, { variant: 'error' });
    }

    if (response?.accessToken) {
      dispatch(setLoginData({...response, userName: response.username, passwordChangeToken: ''}));
    }

    setIsSigningIn(false);

    return navigate('/');
  };

  useEffect(() => {
    if (!passwordChangeToken && !isSigningIn) {
      navigate('/');
    }
  }, [passwordChangeToken, isSigningIn, navigate]);

  if (!passwordChangeToken) {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
        {isSigningIn && <Box sx={{ mt: 1}}>Logging in...</Box> }
      </Box>
    );
  }

  return (
    <CartDrawer>
      <DrawerBox>
        <Title 
          title="Security update" 
          subTitle="To continue using the WSL Tournament Entry System, please change your password to help us improve your account security." 
          showLogo />
        <PasswordRequirements />
        <Box sx={{ mb: 2, mt: 6, display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Field label="Current password *" name="oldPassword" formik={formik} type="password" />
          <Box component="div" sx={{ textAlign: 'right', mt: -1, mb: -1 }}>
            <MuiLink component={Link} to={APP_PATHS.ACCOUNT.FORGOT_PASSWORD} color={theme.palette.text.secondary} sx={{ cursor: 'pointer' }}>
              Forgot Password?
            </MuiLink>
          </Box>
          <Field label="New password *" name="newPassword" formik={formik} type="password" />
          <Field
            label="Confirm new password *"
            name="confirmPassword"
            formik={formik}
            type="password"
          />
          <PrimaryButton
            disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
            sx={{ mt: 4, mb: 1 }}
            onClick={() => {
              formik.submitForm();
            }}
          >
            Change password
          </PrimaryButton>
        </Box>
      </DrawerBox>
    </CartDrawer>
  );
};

export default SecurityUpdate;
