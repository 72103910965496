import { useTournaments } from '@services/api/useTournaments';
import { useTheme } from '@mui/material/styles';
import TournamentsTable from '@components/Tournaments/TournamentsTable/TournamentsTable';
import useMediaQuery from '@mui/material/useMediaQuery';
import TournamentsAccordion from '@components/Tournaments/TournamentsAccordion/TournamentsAccordion';

const TournamentsAll = () => {
  const theme = useTheme();
  const { data: tournaments } = useTournaments(true, true);
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  if (matches && tournaments) {
    return <TournamentsTable tournaments={tournaments.data} />;
  }
  return <>{tournaments && <TournamentsAccordion tournaments={tournaments.data} />}</>;
};

export default TournamentsAll;
