import { Contact } from '@typings/Contact';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link, { LinkProps } from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import React from 'react';

interface ContactDetailsProps {
  contact: Contact;
}

const ContactLink = styled(Link)<LinkProps>(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
}));

const ContactDetails = ({ contact }: ContactDetailsProps): JSX.Element => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div" color="primary">
          {contact.name}
        </Typography>
        <Typography color="secondary">{contact.jobDescription}</Typography>
        <Typography sx={{ mt: 2, gap: 4 }} variant="body2">
          {contact.address?.length &&
            contact.address.map((addressLine, index) => <div key={index}>{addressLine}</div>)}
          {contact.phone && (
            <div style={{ paddingTop: contact.address?.length && '0.5rem' }}>
              <abbr title="Phone">P: </abbr>
              <ContactLink href={`tel:${contact.phone}`}>
                {contact.phoneFormatted ?? contact.phone}
              </ContactLink>
            </div>
          )}
          {contact.email && (
            <div style={{ gap: '0.5rem' }}>
              <ContactLink href={`mailto:${contact.email}`}>{contact.email}</ContactLink>
            </div>
          )}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ContactDetails;
