import { FC, useContext, useEffect } from 'react';
import { Outlet, useMatches } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { APP_PATHS } from '@/router/constants';
import { AuthContext, AuthContextValue } from '@contexts/auth';
import { Box, PaletteMode } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';

import CookieNotice from '../CookieNotice/CookieNotice';
import Footer from '../Footer/Footer';
import SectionHeader from '../SectionHeader/SectionHeader';
import TopMenu from '../TopMenu/TopMenu';

export type ContentLayoutProps = {
  header: string;
  themeMode: PaletteMode;
  toggleMode: () => void;
};

const ContentLayout: FC<ContentLayoutProps> = ({
  themeMode,
  toggleMode,
}) => {
  const navigate = useNavigate();
  const {
    state: { accessToken },
  } = useContext<AuthContextValue>(AuthContext);
  const matches = useMatches();
  const match = matches.find(elem => !!elem.handle);
  let isMarginTop = true;
  let header = '';

  if (match && match.handle && typeof match.handle.isMarginTop !== 'undefined') {
    isMarginTop = match.handle.isMarginTop;
  }

  if (match && match.handle && typeof match.handle.header !== 'undefined') {
    header = match.handle.header;
  }

  useEffect(() => {
    if (!accessToken) {
      navigate(APP_PATHS.ACCOUNT.LOGIN);
    }
  }, [accessToken, navigate]);

  if (!accessToken) {
    return (
      <Container sx={{ height: '100vh' }}>
        <Box
          component="main"
          sx={{
            height: '100%',
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container
      maxWidth="lg"
      sx={{
        pl: 0,
        pr: 0,
      }}
    >
      <TopMenu themeMode={themeMode} toggleMode={toggleMode}></TopMenu>
      {header && <SectionHeader title={header}></SectionHeader>}
      <Box component="main" sx={{ flexGrow: 1, mt: isMarginTop ? 2 : 0, mb: 7 }}>
        <Outlet />
      </Box>
      <Footer />
      <CookieNotice />
    </Container>
  );
};

export default ContentLayout;
