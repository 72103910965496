import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { SyntheticEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CountrySelect } from '@components/Account/CountrySelect';
import DisclaimerMessages from '@components/Account/DisclaimerMessages';
import { CartDrawer, DrawerBox, PrimaryButton } from '@components/common/FormStyledComponents';
import { Field } from '@components/layout/Field/Field';
import { StyledTextField } from '@components/layout/StyledTextField/StyledTextField';
import { Title } from '@components/layout/Title/Title';
import { DATE_FORMAT } from '@constants/date';
import { PRIVACY_LINK, TERMS_LINK } from '@constants/terms';
import { Box, Checkbox, Divider, FormControlLabel, Link, TextFieldProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { applicantsApi } from '@services/api/applicants';
import { ApplicantType } from '@typings/applicants.types';
import { CountryType } from '@typings/country.types';
import { GetDateNonTZ } from '@utils/date';
import { onFieldChange } from '@utils/formik';
import RegistrationValidationSchema from '@validations/RegistrationValidationSchema';

const Register = () => {
  const [acceptTerms, setAcceptTerms] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const formik = useFormik<ApplicantType>({
    initialValues: {
      firstName: '',
      lastName: '',
      address1: '',
      city: '',
      country: '',
      postcode: '',
      phone: '',
      email: '',
      dateOfBirth: null,
      placeOfBirth: '',
      nationality: '',
      ngb: '',
      ngbMember: false,
      username: '',
      password: '',
    },

    validationSchema: RegistrationValidationSchema,

    onSubmit: async (values) => {
      const model: Omit<ApplicantType, 'id'> = {
        ...values,
        firstName: values.firstName,
        lastName: values.lastName,
        address1: values.address1,
        city: values.city,
        country: values.country,
        postcode: values.postcode,
        phone: values.phone,
        email: values.email,
        dateOfBirth: GetDateNonTZ(values.dateOfBirth),
        placeOfBirth: values.placeOfBirth,
        nationality: values.nationality,
        ngb: values.ngb,
        ngbMember: values.ngbMember,
        username: values.username,
        password: values.password,
      };

      try {
        await applicantsApi.applicantsCreate(model);
        navigate('/');
        formik.resetForm();
        return enqueueSnackbar(
          'Your application has been successfully sent. We will contact you very soon!',
          { variant: 'success' }
        );
      } catch (e) {
        return enqueueSnackbar((e as Error).message, { variant: 'error' });
      }
    },
  });

  return (
    <CartDrawer>
      <DrawerBox>
        <Title title="Register for a New World Snooker Account" showLogo />
        <DisclaimerMessages
          message={
            'Registration using this form is to enable the registered user to enter World Snooker Tour events that you are eligible for.'
          }
        />
        <Box sx={{ mb: 2, mt: 4.5, display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Field label="First Name" name="firstName" formik={formik} required={true} />
          <Field label="Last Name" name="lastName" required={true} formik={formik} />
          <Field label="Address" name="address1" required={true} formik={formik} />
          <Field label="City" name="city" required={true} formik={formik} />
          <CountrySelect
            onChange={(country: CountryType['name']) => onFieldChange(formik, 'country', country)}
            defaultValue={formik.values.country}
            error={formik.touched.country === true ? formik.errors.country : undefined}
          />
          <Field label="Post Code" name="postcode" required={true} formik={formik} />
          <Field label="Phone (optional)" name="phone" formik={formik} />
          <Field label="E-mail" name="email" required={true} formik={formik} />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date of Birth *"
              value={formik.values.dateOfBirth}
              inputFormat={DATE_FORMAT}
              disableMaskedInput={true}
              maxDate={new Date()}
              renderInput={(params: TextFieldProps) => (
                <StyledTextField value={formik.values.dateOfBirth} {...params} />
              )}
              onChange={(newValue) => onFieldChange(formik, 'dateOfBirth', newValue)}
            />
          </LocalizationProvider>
          <Field label="Place of Birth" name="placeOfBirth" required={true} formik={formik} />
          <CountrySelect
            overriderLabel="Nationality *"
            onChange={(nationality: CountryType['name']) =>
              onFieldChange(formik, 'nationality', nationality)
            }
            defaultValue={formik.values.nationality}
            error={formik.touched.nationality === true ? formik.errors.nationality : undefined}
          />
          <Field label="Snooker Governing Body (NGB)" name="ngb" formik={formik} />
          <FormControlLabel
            control={<Checkbox />}
            checked={formik.values.ngbMember}
            onChange={(event, value) => onFieldChange(formik, 'ngbMember', value)}
            label={
              <Typography variant="body2" color="textSecondary">
                Are you a member of your snooker NGB?
              </Typography>
            }
          />
        </Box>
        <Divider />
        <Box
          sx={{
            mb: 2,
            mt: 2.5,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: 'bold',
              maxWidth: 450,
            }}
          >
            Create a Username and Password
          </Typography>
          <Field
            label="Username"
            autoComplete="new-username"
            name="username"
            required={true}
            formik={formik}
          />
          <Field
            label="Password"
            type="password"
            autoComplete="new-password"
            name="password"
            required={true}
            formik={formik}
          />
          <Box sx={{ mt: 4.5, display: 'flex' }}>
            <FormControlLabel
              control={<Checkbox />}
              value={acceptTerms}
              onChange={(e: SyntheticEvent<Element, Event>, checked) => setAcceptTerms(checked)}
              label={
                <Typography variant="body2" color="textSecondary">
                  By registering I accept the{' '}
                  <Link href={TERMS_LINK} color="textSecondary" underline="none" fontWeight="bold">
                    Terms & Conditions
                  </Link>{' '}
                  and{' '}
                  <Link
                    href={PRIVACY_LINK}
                    color="textSecondary"
                    underline="none"
                    fontWeight="bold"
                  >
                    Privacy Policy
                  </Link>
                </Typography>
              }
            />
          </Box>
          <Box>
            <PrimaryButton
              disabled={!formik.dirty || !formik.isValid || !acceptTerms}
              sx={{ mt: 1, mb: 1 }}
              onClick={() => {
                formik.submitForm();
              }}
            >
              Register
            </PrimaryButton>
            <Typography variant="caption" color="textSecondary">
              *Required fields
            </Typography>
          </Box>
        </Box>
      </DrawerBox>
    </CartDrawer>
  );
};

export default Register;
