import { CheckoutType } from "@typings/checkout.types";
import { PLAYER_AMATEUR_CONTRACT_LINK, PLAYER_CONTRACT_LINK } from "./contracts";

export const checkoutTournamentTerms = [
  {
    key: 'termsAndConditionsAccepted',
    label: 'I accept the Terms and conditions of Entry as outlined in the Tournament Packs.',
    link: {
      href: PLAYER_CONTRACT_LINK,
      label: 'View Tour Player Contract',
    },
  },
  {
    key: 'playersContractAccepted',
    label: 'I have agreed to the T&C’s in the ‘Players Contract’ which I have signed and returned to World Snooker',
    link: {
      href: PLAYER_AMATEUR_CONTRACT_LINK,
      label: 'View Amateur Player Contract',
    },
  },
];

export const checkoutSeasonTerms = [
  {
    key: 'wpbsaConstitutionAccepted',
    label: 'I have read and understand the WPBSA Constitution.',
    link: {
      href: 'https://www.wpbsa.com/governance/constitution/',
      label: 'View WPBSA Constitution',
    },
  },
  {
    key: 'wpbsaMembersRulesAccepted',
    label: 'I have read, understand and agree to adhere to the WPBSA Members Rules and Regulations.',
    link: {
      href: 'http://www.wpbsa.com/governance/members-rules/',
      label: 'View WPBSA Members Rules and Regulations',
    },
  },
  {
    key: 'wpbsaDisciplinaryRulesAccepted',
    label: 'I have read, understand and agree to adhere to the WPBSA Disciplinary Rules.',
    link: {
      href: 'http://www.wpbsa.com/governance/disciplinary-outcomes/',
      label: 'View WPBSA Disciplinary Rules',
    },
  },
];

export const checkoutEmptyValues: CheckoutType = {
  firstnames: '',
  lastName: '',
  address1: '',
  address2: '',
  city: '',
  country2Char: '',
  postcode: '',
  email: '',
  phone: '',
};

