import * as yup from 'yup';
import passwordValidationSchema from '@validations/PasswordValidationSchema';
import PostcodeValidationSchema from '@validations/PostcodeValidationSchema';
import PhoneValidationSchema from '@validations/PhoneValidationSchema';
import EmailValidationSchema from '@validations/EmailValidationSchema';

const RegistrationValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('First Name is required field')
    .min(3, 'First name have to be 3-20 characters long')
    .max(20, 'First name have to be 3-20 characters long'),
  lastName: yup
    .string()
    .required('Last name is required field')
    .min(3, 'Last name have to be 3-20 characters long')
    .max(20, 'Last name have to be 3-20 characters long'),
  email: EmailValidationSchema.required('Email is required field'),
  dateOfBirth: yup.date().typeError('Invalid Date').required('Date of Birth is required field'),
  address1: yup
    .string()
    .required('Address is a required field')
    .max(100, {
      message: 'Address field can have only 100 characters',
    })
    .required('Address is required field'),
  address2: yup
    .string()
    .max(100, {
      message: 'Address field can have only 100 characters',
    })
    .nullable(),
  city: yup
    .string()
    .max(40, {
      message: 'City field can have only 40 characters',
    })
    .required('City is required field'),
  country: yup
    .string()
    .max(150, 'Country field can have only 150 characters')
    .required('Country is required field'),
  postcode: PostcodeValidationSchema.required('Postcode is required field'),
  phone: PhoneValidationSchema,
  placeOfBirth: yup
    .string()
    .max(100, 'Place of birth field can have only 100 characters')
    .required('Place of Birth is a required field'),
  nationality: yup
    .string()
    .max(150, 'Nationality field can have only 150 characters')
    .required('Nationality is required field'),
  ngb: yup.string().max(50),
  ngbMember: yup.boolean(),
  username: yup.string().min(3).max(30).required('Username is a required field'),
  password: passwordValidationSchema.required('Enter your password'),
});

export default RegistrationValidationSchema;
