import { useEffect, useState } from 'react';
import { FormItemProps } from '@typings/common';
import SelectBox from '@components/layout/SelectBox/SelectBox';
import { useCountries } from '@services/api/useCountries';
import { CountryType } from '@typings/country.types';

export const CountryCodeSelect = ({
  defaultValue,
  onChange,
  disabled,
  overriderLabel,
  error,
}: FormItemProps<CountryType['name']>) => {
  const [selectedOption, setSelectedOption] = useState<CountryType['name'] | null>(
    defaultValue ? defaultValue : null
  );
  const { data, isLoading, error: dataError } = useCountries();
  const [options, setOptions] = useState<{ label: string, value: string }[]>([]);

  useEffect(() => {
    if (!data || !data.data.items) {
      return;
    }

    const countries = data.data.items.filter(country => country.isUsableOnSagePay);
    const options = countries
      .map((country: CountryType) => ({ label: country.name, value: country.isoCode2 }))
      .sort((a, b) => a.label > b.label ? 1 : -1);

    setOptions(options);
  }, [data]);

  const handleSelectedCountry = (option: CountryType['name']) => {
    setSelectedOption(option);
    onChange?.(option);
  };

  useEffect(() => {
    setSelectedOption(defaultValue || null);
  }, [defaultValue]);

  return (
    <SelectBox
      defaultValue={selectedOption}
      handleChange={handleSelectedCountry}
      data={{ options, isLoading, error: dataError }}
      disabled={disabled}
      label={overriderLabel || 'Country *'}
      error={error}
    />
  );
};
