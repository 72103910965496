import DoneIcon from '@mui/icons-material/Done';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

interface TransactionIconProps {
  status: 'success' | 'failure';
}
const TransactionIcon = ({ status }: TransactionIconProps) => {
  const theme = useTheme();
  const sizesProps: SxProps = {
    height: theme.spacing(4.25),
    width: theme.spacing(4.25),
  };
  if (status === 'failure') {
    return <CloseIcon sx={sizesProps} />;
  }

  return <DoneIcon sx={sizesProps} />;
};

export default TransactionIcon;
