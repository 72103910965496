import { createSearchParams, useNavigate } from 'react-router-dom';

import { APP_PATHS } from '@/router/constants';
import TournamentsAccordion from '@components/Tournaments/TournamentsAccordion/TournamentsAccordion';
import TournamentsTable from '@components/Tournaments/TournamentsTable/TournamentsTable';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTournaments } from '@services/api/useTournaments';

const TournamentsEntered = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { data: tournaments } = useTournaments(true, false);
  const todayDate = new Date().setHours(0, 0, 0, 0);

  const handleBookPractice = (tournamentId: number, tournamentName: string) => {
    navigate({
      pathname: APP_PATHS.PRACTICE.ROOT,
      search: `?${createSearchParams({
        tournamentId: String(tournamentId),
        tournamentName: tournamentName,
      })}`,
    });
  };

  const checkIsTournamentFinished = (day: string) => {
    return todayDate.valueOf() - new Date(day).valueOf() <= 0;
  };

  if (matches && tournaments) {
    return (
      <TournamentsTable
        tournaments={tournaments.data}
        bookPractice={{ handleBooking: handleBookPractice, checkIsTournamentFinished }}
      />
    );
  }

  return (
    <>
      {tournaments && (
        <TournamentsAccordion
          tournaments={tournaments.data}
          bookPractice={{ handleBooking: handleBookPractice, checkIsTournamentFinished }}
        />
      )}
    </>
  );
};

export default TournamentsEntered;
