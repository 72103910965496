import {
  CartDrawer,
  SemiBoldTypography,
  TransactionBox,
  TransactionMainTypography,
} from '@components/common/FormStyledComponents';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useTransactionStatus } from '@services/api/useTransactionStatus';
import TransactionIcon from '@components/Transactions/TransactionIcon';
import TransactionLoader from '@components/Transactions/TransactionLoader';

interface TransactionSuccessProps {
  vendorTxCode: string;
}

const TransactionSuccess = ({ vendorTxCode }: TransactionSuccessProps) => {
  const theme = useTheme();
  const { data, isLoading } = useTransactionStatus(vendorTxCode);

  if (!data) {
    return null;
  }

  if (isLoading) {
    return <TransactionLoader />;
  }

  return (
    <CartDrawer sx={{ padding: theme.spacing(4, 3) }}>
      <TransactionBox>
        <TransactionMainTypography isSuccess>
          <TransactionIcon status="success" /> Transaction Successful
        </TransactionMainTypography>
        <SemiBoldTypography>
          Thank you, your order has been successfully completed. Your order number is{' '}
          <strong>{data.data.basketId}</strong>, please quote this number if you wish to follow up
          on this transaction. An invoice PDF has been sent via email.
        </SemiBoldTypography>
      </TransactionBox>
    </CartDrawer>
  );
};

export default TransactionSuccess;
