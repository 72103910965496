
import { useFormik } from 'formik';
import { useState } from 'react';
import { object, string } from 'yup';

import { EmailConfirmation } from '@components/Account/EmailConfirmation';
import { CartDrawer, DrawerBox, PrimaryButton } from '@components/common/FormStyledComponents';
import { Field } from '@components/layout/Field/Field';
import { Title } from '@components/layout/Title/Title';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { accountsApi, ClientType } from '@services/api/accounts';
import { ForgottenPasswordType } from '@typings/account.types';

const ForgotPassword = () => {
  const [confirmation, setConfirmation] = useState(false);
  const [error, setError] = useState(false);

  const formik = useFormik({
    initialValues: {
      usernameOrEmail: '',
    },

    validationSchema: object().shape({
      usernameOrEmail: string().required('Username or E-mail are required'),
    }),

    onSubmit: async (values) => {
      const model: ForgottenPasswordType = {
        ...values,
        clientType: ClientType.PLAYER_PORTAL,
      };

      try {
        await accountsApi.forgotPassword(model);
        setConfirmation(true);
        formik.resetForm();
      } catch (e) {
        setError(true);
      }
    },
  });

  return (
    <CartDrawer>
      <DrawerBox>
        <Title title="WSL Tournament entry system" subTitle="Recover password" showLogo />
        <Box sx={{ mb: 2, mt: 13.75, display: 'flex', flexDirection: 'column', gap: 3 }}>
          {confirmation
            ? <EmailConfirmation />
            : (
            <Box>
              <Field label="Your e-mail or username *" name="usernameOrEmail" formik={formik} />
              {error && (
                <Typography variant="body1" color="error.main">
                  The application has encountered an error while requesting password reset.
                </Typography>
              )}
              <PrimaryButton
                disabled={!formik.dirty || !formik.isValid}
                sx={{ mt: 4, mb: 1 }}
                onClick={formik.submitForm}
              >
                Email me a recovery link
              </PrimaryButton>
            </Box>
          )}
        </Box>
      </DrawerBox>
    </CartDrawer>
  );
};

export default ForgotPassword;
