import { Home } from '@/pages';

export const TournamentRoutes = [
  {
    path: '',
    element: <Home />,
    exact: true,
    handle: { title: 'Tournaments', header: 'Tournaments' },
  },
];
