import { request } from '@services/request';
import { API_PATH } from '@constants/api';
import { ApiMethod } from '@services/api/index';
import { TournamentsType, GetTournamentType, EntryPackType } from '@typings/tournaments.types';

const getTournaments = async (
  includeEntered: boolean,
  includeAvailbale: boolean
): Promise<TournamentsType> => {
  return await request(
    `${API_PATH.tournamentsPlayer}?includeEntered=${includeEntered}&includeAvailable=${includeAvailbale}`,
    ApiMethod.GET
  );
};

const getTournamentById = async (tournamentId: string): Promise<GetTournamentType> => {
  return await request(`${API_PATH.tournamentsPlayer}/${tournamentId}`, ApiMethod.GET);
};

const getTournamentPack = async (tournamentId: number): Promise<EntryPackType> => {
  return await request(`${API_PATH.tournaments}/${tournamentId}/tournament-pack`, ApiMethod.GET);
};

export const tournamentsApi = {
  getTournaments,
  getTournamentById,
  getTournamentPack,
};
