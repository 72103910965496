import CheckoutSeason from '@/pages/checkout/season';
import CheckoutTournament from '@/pages/checkout/tournament';

import { APP_PATHS } from './constants';

const { CHECKOUT } = APP_PATHS;

export const CheckoutRoutes = [
  {
    path: CHECKOUT.SEASON,
    element: <CheckoutSeason />,
    handle: { title: 'Checkout', header: 'Checkout' },
  },
  {
    path: CHECKOUT.TOURNAMENT,
    element: <CheckoutTournament />,
    handle: { title: 'Checkout', header: 'Checkout' },
  },
];
