import React, { FC } from 'react';

import { APP_PATHS, EXTERNAL_PATHS } from '@/router/constants';
import DrawerLink from '@components/layout/TopMenu/DrawerLink';
import { ThemeModeSwitch } from '@components/layout/TopMenu/ThemeModeSwitch';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Drawer, List, ListItemButton, PaletteMode } from '@mui/material';
import Divider from '@mui/material/Divider';
import { alpha, useTheme } from '@mui/material/styles';

interface CustomDrawerProps {
  drawerState: boolean;
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  themeMode: PaletteMode;
  toggleMode: () => void;
  handleLogout: VoidFunction;
  handleProfile: VoidFunction;
  handleChangePassword: VoidFunction;
}

const CustomDrawer: FC<CustomDrawerProps> = ({
  drawerState,
  toggleDrawer,
  themeMode,
  toggleMode,
  handleLogout,
  handleProfile,
  handleChangePassword,
}) => {
  const theme = useTheme();

  return (
    <Drawer
      anchor="top"
      open={drawerState}
      onClose={toggleDrawer(false)}
      SlideProps={{ direction: 'left' }}
      PaperProps={{ elevation: 0, sx: { backgroundColor: 'transparent', backgroundImage: 'none' } }}
      ModalProps={{
        BackdropProps: { sx: { backgroundColor: alpha(theme.header.background, 0.95) } },
      }}
    >
      <Box role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
        <List sx={{ pt: 0, pb: 0 }}>
          <ListItemButton
            onClick={toggleDrawer(false)}
            sx={{
              height: 72,
              justifyContent: 'space-between',
              backgroundColor: theme.header.background,
            }}
          >
            <ThemeModeSwitch mode={themeMode} toggleMode={toggleMode} />
            <CloseIcon fontSize="large"></CloseIcon>
          </ListItemButton>
          <Divider />
          <DrawerLink text="WST.TV" url={EXTERNAL_PATHS.WST_TV} openInNewWindow={true} />
          <DrawerLink text="TOURNAMENTS" url={APP_PATHS.TOURNAMENTS.ROOT} />
          <DrawerLink text="SPONSORS" url={APP_PATHS.SPONSORS.ROOT} />
          <DrawerLink text="CONTACT" url={APP_PATHS.CONTACT.ROOT} />
          <DrawerLink
            text="PROFILE DETAILS"
            url={APP_PATHS.PROFILE.ROOT}
            icon={<PersonIcon fontSize="small" />}
            onClick={handleProfile}
          />
          <DrawerLink
            text="CHANGE PASSWORD"
            url={APP_PATHS.PROFILE.CHANGE_PASSWORD}
            icon={<SettingsIcon fontSize="small" />}
            onClick={handleChangePassword}
          />
          <DrawerLink
            text="LOGOUT"
            url=""
            icon={<LogoutIcon fontSize="small" />}
            onClick={handleLogout}
          />
        </List>
      </Box>
    </Drawer>
  );
};

export default CustomDrawer;
