import { useEffect, useState } from 'react';
import { FormItemProps } from '@typings/common';
import SelectBox from '@components/layout/SelectBox/SelectBox';
import { useCountries } from '@services/api/useCountries';
import { CountryType } from '@typings/country.types';

export const CountrySelect = ({
  defaultValue,
  onChange,
  disabled,
  overriderLabel,
  error,
}: FormItemProps<CountryType['name']>) => {
  const [selectedOption, setSelectedOption] = useState<CountryType['name'] | null>(
    defaultValue ? defaultValue : null
  );
  const { data, isLoading, error: dataError } = useCountries('?canBeUseAsNationality=true');
  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    if (data?.data.items) {
      setOptions(data.data.items.map((country: CountryType) => country.name));
    }
  }, [data]);

  const handleSelectedCountry = (option: CountryType['name']) => {
    setSelectedOption(option);
    onChange?.(option);
  };

  useEffect(() => {
    setSelectedOption(defaultValue || null);
  }, [defaultValue]);

  return (
    <SelectBox
      defaultValue={selectedOption}
      handleChange={handleSelectedCountry}
      data={{ options, isLoading, error: dataError }}
      disabled={disabled}
      label={overriderLabel || 'Country *'}
      error={error}
    />
  );
};
