import { request } from '@services/request';
import { API_PATH } from '@constants/api';
import { ApiMethod } from '@services/api/index';
import { TransactionStatusType } from '@typings/billings.types';

const getTransactionStatus = async (vendorTxCode: string): Promise<TransactionStatusType> => {
  return await request(`${API_PATH.transactionStatus}?vendorTxCode=${vendorTxCode}`, ApiMethod.GET);
};

export const billingsApi = {
  getTransactionStatus,
};
