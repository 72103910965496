import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { APP_PATHS } from '@/router/constants';
import { StyledTab, StyledTabs } from '@components/common/FormStyledComponents';
import TournamentsAll from '@components/Tournaments/TournamentsAll/TournamentsAll';
import TournamentsAvailable from '@components/Tournaments/TournamentsAvailable/TournamentsAvailable';
import TournamentsEntered from '@components/Tournaments/TournamentsEntered/TournamentsEntered';
import { Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { seasonsApi } from '@services/api/seasons';
import { MembershipStatusType } from '@typings/seasons.types';

const a11yProps = (index: number) => {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
};

export const Home = () => {
  const theme = useTheme();
  const [value, setValue] = useState(2);
  const navigate = useNavigate();
  const [{ data: seasonStatus }, setSeasonMembershipStatus] = useState<MembershipStatusType>({} as MembershipStatusType);

  useEffect(() => {
    seasonsApi.getSeasonMembershipStatus().then(res => setSeasonMembershipStatus(res));
  }, []);

  const handleChange = (event: unknown, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      {seasonStatus?.canPurchaseMembership &&
        <Box sx={{ display: 'flex', padding: theme.spacing(1, 5) }}>
            <Button variant="contained" color="secondary" fullWidth onClick={() => navigate(APP_PATHS.CHECKOUT.SEASON)}>
              Purchase WPBSA membership for the season {seasonStatus.purchasableSeasonName}
            </Button>
        </Box>
      }

      <StyledTabs value={value} onChange={handleChange} textColor="primary" variant="fullWidth">
        <StyledTab label="All" {...a11yProps(0)} />
        <StyledTab label="Entered" {...a11yProps(1)} />
        <StyledTab label="Available" {...a11yProps(2)} />
      </StyledTabs>
      {value === 0 && <TournamentsAll />}
      {value === 1 && <TournamentsEntered />}
      {value === 2 && <TournamentsAvailable />}
    </div>
  );
};
