import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

interface MessageFieldProps {
  message: string;
  showDivider: boolean;
}

const MessageField: FC<MessageFieldProps> = ({ message, showDivider }) => {
  return (
    <>
      <Typography variant="body2">{message}</Typography>
      {showDivider && <Divider sx={{ mt: 1, mb: 1 }} />}
    </>
  );
};

export default MessageField;
