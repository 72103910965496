import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContextValue } from 'src/contexts/auth/types';

import { APP_PATHS } from '@/router/constants';
import ImportantMessages from '@components/Account/ImportantMessages';
import PasswordField from '@components/Account/PasswordField';
import { CartDrawer, DrawerBox, PrimaryButton } from '@components/common/FormStyledComponents';
import Footer from '@components/layout/Footer/Footer';
import { StyledTextField } from '@components/layout/StyledTextField/StyledTextField';
import { Title } from '@components/layout/Title/Title';
import { AuthContext } from '@contexts/auth';
import { setLoginData } from '@contexts/auth/reducer/actions';
import { Box } from '@mui/material';
import MuiLink from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { signIn } from '@services/authorization';

export const loginButtonTestId = 'login-button-test-id';
export const usernameFieldTestId = 'login-username-test-id';
export const passwordFieldTestId = 'login-password-test-id';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const { dispatch } = useContext<AuthContextValue>(AuthContext);

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter' && isSaveEnabled) {
      await handleLogin();
    }
  };

  const handleLogin = async () => {
    setIsSubmitting(true);

    const response = await signIn({ username, password });

    setError(response?.error ?? null);

    if (response?.passwordChangeRequired && response?.passwordChangeToken) {
      dispatch(setLoginData({ 
        accessToken: '', 
        refreshToken: '', 
        userName: response.username, 
        passwordChangeToken: response.passwordChangeToken
      })); 

      navigate(APP_PATHS.ACCOUNT.SECURITY_UPDATE);
    } else if (response?.accessToken) {
      dispatch(setLoginData({...response, userName: response.username, passwordChangeToken: ''}));

      navigate('/');
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    setIsSaveEnabled(username && password ? true : false);
  }, [username, password]);

  return (
    <CartDrawer>
      <DrawerBox onKeyUp={handleKeyPress}>
        <Title title="LOGIN to the WSL Tournament Entry System" showLogo />
        <ImportantMessages />
        <Box sx={{ mb: 2, mt: 4.5 }}>
          <StyledTextField
            id="username"
            label="Username *"
            autoComplete="new-username"
            data-testid={usernameFieldTestId}
            sx={{ width: '100%' }}
            value={username}
            onChange={handleUsernameChange}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <PasswordField
            password={password}
            setPassword={setPassword}
            data-testid={passwordFieldTestId}
          />
        </Box>
        <Typography component="div" variant="body2" sx={{ color: theme.palette.error.main }}>
          {error}
        </Typography>
        <PrimaryButton
          disabled={!isSaveEnabled || isSubmitting}
          sx={{ mt: 4 }}
          onClick={handleLogin}
          data-testid={loginButtonTestId}
        >
          {isSubmitting ? 'LOGGIN IN...' : 'LOGIN'}
        </PrimaryButton>
        <Box component="div" sx={{ textAlign: 'right', mt: 1 }}>
          <MuiLink component={Link} to={APP_PATHS.ACCOUNT.FORGOT_PASSWORD} color={theme.palette.text.secondary} sx={{ cursor: 'pointer' }}>
            Forgot Password?
          </MuiLink>
        </Box>
        <Box component="div" sx={{ textAlign: 'center', mt: 3.75, mb: 9 }}>
          <Typography component="span" sx={{ pr: 1, color: theme.palette.text.secondary }}>
            Don&apos;t have an account?
          </Typography>
          <MuiLink component={Link} to={APP_PATHS.ACCOUNT.REGISTER} underline="none" sx={{ fontWeight: 'bold', cursor: 'pointer' }}>
            REGISTER
          </MuiLink>
        </Box>
        <Footer />
      </DrawerBox>
    </CartDrawer>
  );
};

export default Login;
