import { useSearchParams } from 'react-router-dom';

import TransactionFailed from '@components/Transactions/TransactionFailed';

const PaymentsFailure = () => {
  const [searchParams] = useSearchParams();
  const vendorTxCode = searchParams.get('vendorTxCode');

  if (!vendorTxCode) {
    return null;
  }

  return <TransactionFailed vendorTxCode={vendorTxCode as string} />;
};

export default PaymentsFailure;
