import { ItemFetcher } from '@services/index';
import useSWR from 'swr';
import { SponsorsType } from '@typings/Sponsor';
import { sponsorsApi } from '@services/api/sponsors';

const itemFetcher: ItemFetcher<SponsorsType> = async () => await sponsorsApi.getSponsors();

export function useSponsors() {
  const { data, error, mutate } = useSWR(['get-sponsors'], itemFetcher);

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
}
