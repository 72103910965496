import * as yup from 'yup';
import passwordValidationSchema from '@validations/PasswordValidationSchema';

const ResetPasswordValidationSchema = yup.object().shape({
  newPassword: passwordValidationSchema
    .required('Enter your new password'),
  confirmPassword: passwordValidationSchema
    .oneOf([yup.ref('newPassword'), null], 'Password entries do not match')
    .required('Confirm your new password'),
});

export default ResetPasswordValidationSchema;
