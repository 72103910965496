import { request } from '@services/request';
import { API_PATH } from '@constants/api';
import { ApiMethod } from '@services/api/index';
import {
  PracticeDaysType,
  PracticeTableBlocksType,
  PracticeTableBookingType,
} from '@typings/practice.types';

const getPracticeTableDays = async (tournamentId: number): Promise<PracticeDaysType> => {
  const
      sortOptions =
          {
            SortBy: 'Date',
            SortOrder: 'Asc'
          };
  const sort = JSON.stringify(sortOptions)
  
  return await request(`${API_PATH.practiceDays}?tournamentId=${tournamentId}&sortOptions=${sort}`, ApiMethod.GET);
};

const getPracticeTableBlocks = async (
  tournamentId: number,
  dayId: number
): Promise<PracticeTableBlocksType> => {
  return await request(
    `${API_PATH.practiceBlocks}/${dayId}?tournamentId=${tournamentId}`,
    ApiMethod.GET
  );
};

const postPracticeTableBooking = async (tableBooking: Partial<PracticeTableBookingType>) => {
  return await request(`${API_PATH.practiceTableBookings}`, ApiMethod.POST, tableBooking);
};

const deletePracticeTableBooking = async (bookingId: number) => {
  return await request(`${API_PATH.practiceTableBookings}/${bookingId}`, ApiMethod.DELETE);
};

export const practiceTableDaysApi = {
  getPracticeTableDays,
  getPracticeTableBlocks,
  postPracticeTableBooking,
  deletePracticeTableBooking,
};
