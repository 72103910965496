import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

export const EmailConfirmation = (): JSX.Element => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Typography component="span" fontWeight="bold" fontSize="1.2rem">
        An email with further
      </Typography>
      <Typography component="span" fontWeight="bold" fontSize="1.2rem">
        instructions has been sent
      </Typography>
      <Typography variant="caption" color="textSecondary">
        Please click the link when you get it
      </Typography>
    </Box>
  );
};
