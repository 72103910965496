import PracticeTableBooking from '@components/PracticeTableBooking/PracticeTableBooking';
import {
  DayBox,
  DaysBoxesWrapper,
  TournamentNameBox,
} from '@components/PracticeTableBooking/StyledBookingComponents';
import {
  BoldTypography,
  ItalicTypography,
  NormalBoldTypography,
} from '@components/common/FormStyledComponents';
import { DATE_FORMAT, DATE_FORMAT_WITHOUT_YEAR } from '@constants/date';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { usePracticeTableBlocks } from '@services/api/usePracticeTableBlocks';
import { usePracticeTableDays } from '@services/api/usePracticeTableDays';
import { PracticeDayType, PracticeTableBlocksType } from '@typings/practice.types';
import { formatDate } from '@utils/date';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const Practice = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const tournamentId = searchParams.get('tournamentId') || '';
  const tournamentName = searchParams.get('tournamentName') || '';

  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  const [activeDay, setActiveDay] = useState<PracticeDayType>();
  const [practiceBlocks, setPracticeBlocks] = useState<PracticeTableBlocksType | undefined>();
  const { data: days } = usePracticeTableDays(tournamentId);
  const { data: practiceTableBlocks, mutate } = usePracticeTableBlocks(
      tournamentId,
      activeDay?.practiceDayId
  );

  useEffect(() => {
    const day = days?.data.items.find((day) => checkIsActualDay(day.date));
    if (!day) {
      return;
    }
    return setActiveDay(day);
  }, [days]);

  useEffect(() => {
    if (!practiceTableBlocks) {
      return;
    }
    if (!practiceTableBlocks?.data?.practiceBlockDetails) {
        setPracticeBlocks(practiceTableBlocks);
        return;
    }
    const blocks = {
      ...practiceTableBlocks,
      data: {
        ...practiceTableBlocks.data,
        practiceBlockDetails: practiceTableBlocks.data.practiceBlockDetails.sort((a, b) => a.start > b.start ? 1 : -1)
      }

    }
    setPracticeBlocks(blocks);
  }, [practiceTableBlocks]);

  const checkIsPreviousDay = (day: Date) => {
    return todayDate.valueOf() - new Date(day).valueOf() > 0;
  };

  const checkIsActualDay = (day: Date) => {
    return (
        formatDate(day, DATE_FORMAT) === formatDate(todayDate, DATE_FORMAT) ||
        todayDate.valueOf() - new Date(day).valueOf() < 0
    );
  };

  const handleRefresh = () => {
    mutate();
  };

  return (
      <>
        <TournamentNameBox>{tournamentName}</TournamentNameBox>
        <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              px: 3,
            }}
        >
          <DaysBoxesWrapper>
            {days?.data?.items?.map((day) => (
                <DayBox
                    key={day.practiceDayId}
                    isPreviousDay={checkIsPreviousDay(day.date)}
                    isActiveDay={activeDay === day}
                    onClick={() => {
                      if (checkIsPreviousDay(day.date)) {
                        return;
                      }
                      setActiveDay(day);
                    }}
                >
                  {formatDate(day.date, DATE_FORMAT_WITHOUT_YEAR)}
                </DayBox>
            ))}
          </DaysBoxesWrapper>
        </Box>

        <Box sx={{ padding: theme.spacing(3) }}>
          <BoldTypography>PRACTICE TABLE BOOKINGS</BoldTypography>
          <Box>
            <ItalicTypography>
              Maximum Bookings:
              <NormalBoldTypography>{activeDay?.maxPracticesAllowed}</NormalBoldTypography>
            </ItalicTypography>
            <ItalicTypography>
              Booking Cooldown:
              <NormalBoldTypography>{activeDay?.practiceBlockCoolDown}</NormalBoldTypography>
            </ItalicTypography>
            <ItalicTypography>
              Cancel Window:
              <NormalBoldTypography>{activeDay?.cancelWindow}</NormalBoldTypography>
            </ItalicTypography>
          </Box>
        </Box>
        {practiceBlocks &&
            practiceBlocks?.data.practiceBlockDetails.length !== 0 &&
            activeDay &&
            tournamentName && (
                <PracticeTableBooking
                    practiceTableBlocks={practiceBlocks}
                    activeDay={activeDay}
                    refresh={handleRefresh}
                    tournamentName={tournamentName.toString()}
                />
            )}
      </>
  );
};

export default Practice;