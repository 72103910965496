import { CartDrawer, DrawerBox } from '@components/common/FormStyledComponents';
import { useProfile } from '@services/api/useProfile';
import { StyledDisabledTextField } from '@components/layout/StyledTextField/StyledDisabledTextField';
import { formatDate } from '@utils/date';
import { useTheme } from '@mui/material/styles';

const Profile = () => {
  const theme = useTheme();
  const { data } = useProfile();

  if (!data) {
    return null;
  }

  return (
    <CartDrawer>
      <DrawerBox sx={{ gap: theme.spacing(2) }}>
        {data?.data.names[0]?.firstName && (
          <StyledDisabledTextField value={data.data.names[0].firstName} label="First Name" />
        )}
        {data?.data.names[0]?.lastName && (
          <StyledDisabledTextField value={data?.data.names[0]?.lastName} label="Last Name" />
        )}
        {data?.data.addresses[data.data.addresses.length - 1]?.address1 && (
          <StyledDisabledTextField
            value={data.data.addresses[data.data.addresses.length - 1].address1}
            label="Address"
          />
        )}
        {data?.data.addresses[data.data.addresses.length - 1]?.countryName && (
          <StyledDisabledTextField
            value={data.data.addresses[data.data.addresses.length - 1].countryName}
            label="Country"
          />
        )}
        {data?.data.addresses[data.data.addresses.length - 1]?.postcode && (
          <StyledDisabledTextField
            value={data.data.addresses[data.data.addresses.length - 1].postcode}
            label="Post Code"
          />
        )}
        {data?.data.contacts.find((contact) => {
          return contact.contactTypes === 'MobilePhone';
        })?.contactValue && (
          <StyledDisabledTextField
            value={
              data.data.contacts.find((contact) => {
                return contact.contactTypes === 'MobilePhone';
              })?.contactValue
            }
            label="Phone"
          />
        )}
        {data?.data.contacts.find((contact) => {
          return contact.contactTypes === 'EmailPrimary';
        })?.contactValue && (
          <StyledDisabledTextField
            value={
              data.data.contacts.find((contact) => {
                return contact.contactTypes === 'EmailPrimary';
              })?.contactValue
            }
            label="E-mail"
          />
        )}
        {data?.data?.dateOfBirth && (
          <StyledDisabledTextField
            value={formatDate(data.data.dateOfBirth)}
            label="Date of Birth"
          />
        )}
        {data?.data?.placeOfBirth && (
          <StyledDisabledTextField
            value={data.data.placeOfBirth.cityName}
            label="Place of Birth"
          />
        )}
        {data?.data?.country?.name && (
          <StyledDisabledTextField
            value={data.data.country.name}
            label="Nationality"
          />
        )}
      </DrawerBox>
    </CartDrawer>
  );
};

export default Profile;
