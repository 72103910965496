import { Navigate, RouteObject } from 'react-router-dom';

import AuthLayout from '@/components/layout/AuthLayout/AuthLayout';
import ContentLayout from '@/components/layout/ContentLayout/ContentLayout';
import { App } from '@/pages/App';

import { AccountRoutes } from './account';
import { CheckoutRoutes } from './checkout';
import { ContactRoutes } from './contact';
import { PaymentsRoutes } from './payments';
import { PracticeRoutes } from './practice';
import { ProfileRoutes } from './profile';
import { SponsorsRoutes } from './sponsors';
import { TournamentRoutes } from './tournaments';
import { APP_PATHS } from './constants';

const AppAuthLayout = (
  <App>
    <AuthLayout />
  </App>
);

const AppContentLayout = (
  <App>
    <ContentLayout />
  </App>
);

export const routes: RouteObject[] = [
  {
    path: APP_PATHS.ACCOUNT.ROOT,
    element: AppAuthLayout,
    children: AccountRoutes,
  },
  {
    path: APP_PATHS.TOURNAMENTS.ROOT,
    element: AppContentLayout,
    children: TournamentRoutes,
  },
  {
    path: APP_PATHS.PROFILE.ROOT,
    element: AppContentLayout,
    children: ProfileRoutes,
  },
  {
    path: APP_PATHS.CHECKOUT.ROOT,
    element: AppContentLayout,
    children: CheckoutRoutes,
  },
  {
    path: APP_PATHS.CONTACT.ROOT,
    element: AppContentLayout,
    children: ContactRoutes,
  },
  {
    path: APP_PATHS.PAYMENTS.ROOT,
    element: AppContentLayout,
    children: PaymentsRoutes,
  },
  {
    path: APP_PATHS.PRACTICE.ROOT,
    element: AppContentLayout,
    children: PracticeRoutes,
  },
  {
    path: APP_PATHS.SPONSORS.ROOT,
    element: AppContentLayout,
    children: SponsorsRoutes,
  },
  { path: '*', element: <Navigate to="/" /> },
];
