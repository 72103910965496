import { Navigate } from 'react-router-dom';

import ForgotPassword from '@/pages/account/forgot-password';
import Login from '@/pages/account/login';
import Register from '@/pages/account/register';
import ResetPassword from '@/pages/account/reset-password';
import SecurityUpdate from '@/pages/account/security-update';

import { APP_PATHS } from './constants';

const { ACCOUNT } = APP_PATHS;

export const AccountRoutes = [
  {
    path: '',
    exact: true,
    element: <Navigate to="/" />
  },
  {
    path: ACCOUNT.FORGOT_PASSWORD,
    element: <ForgotPassword />,
    handle: { title: 'Password reset'},
  },
  {
    path: ACCOUNT.LOGIN,
    element: <Login />,
    handle: { title: 'Login'},
  },
  {
    path: ACCOUNT.REGISTER,
    element: <Register />,
    handle: { title: 'Registration'},
  },
  {
    path: ACCOUNT.RESET_PASSWORD,
    element: <ResetPassword />,
    handle: { title: 'Reset password'},
  },
  {
    path: ACCOUNT.SECURITY_UPDATE,
    element: <SecurityUpdate />,
    handle: { title: 'Change password', header: 'Change password' },
  },
];
