import { createTheme, ThemeOptions } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { wstBlack, wstCommonThemeOptions, wstDarkGrey, wstDarkGreyPaper } from './wstTheme';

export const wstDarkThemeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    background: {
      default: wstDarkGrey,
      paper: wstDarkGreyPaper,
    },
    text: {
      primary: '#ffffff',
    },
  },
  header: {
    background: wstBlack,
    text: '#ffffff',
  },
  resources: {
    logo: {
      src: '/images/wst-logo-no-padding.svg',
      background: wstBlack,
    },
  },
};

export const wstDarkTheme = createTheme(deepmerge(wstDarkThemeOptions, wstCommonThemeOptions));
