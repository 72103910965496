import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import PasswordRequirements from '@components/Account/PasswordRequirements';
import { CartDrawer, DrawerBox, PrimaryButton } from '@components/common/FormStyledComponents';
import { Field } from '@components/layout/Field/Field';
import { Box } from '@mui/material';
import { accountsApi, ClientType } from '@services/api/accounts';
import { ChangePasswordType } from '@typings/account.types';
import ChangePasswordValidationSchema from '@validations/ChangePasswordValidationSchema';

const ChangePassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      clientType: ClientType.PLAYER_PORTAL,
    },

    validationSchema: ChangePasswordValidationSchema,

    onSubmit: async (values) => {
      const model: Omit<ChangePasswordType, 'id'> = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
        clientType: values.clientType,
      };

      try {
        await accountsApi.changePassword(model);
        formik.resetForm();
        enqueueSnackbar('The password has been changed.', { variant: 'success' });
        return navigate('/');
      } catch (e) {
        return enqueueSnackbar((e as Error).message, { variant: 'error' });
      }
    },
  });

  return (
    <CartDrawer>
      <DrawerBox>
        <PasswordRequirements />
        <Box sx={{ mb: 2, mt: 10, display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Field label="Current password *" name="oldPassword" formik={formik} type="password" />
          <Field label="New password *" name="newPassword" formik={formik} type="password" />
          <Field
            label="Confirm new password *"
            name="confirmPassword"
            formik={formik}
            type="password"
          />
          <PrimaryButton
            disabled={!formik.dirty || !formik.isValid}
            sx={{ mt: 4, mb: 1 }}
            onClick={() => {
              formik.submitForm();
            }}
          >
            Change password
          </PrimaryButton>
        </Box>
      </DrawerBox>
    </CartDrawer>
  );
};

export default ChangePassword;
