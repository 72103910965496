import {
  PracticeDayType,
  PracticeTableBlocksType,
  PracticeTableBookingType,
  PracticeTableType,
} from '@typings/practice.types';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import {
  PracticeTableCell,
  StripedTimeTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  TableBox,
} from '@components/PracticeTableBooking/StyledTable';
import { formatDate } from '@utils/date';
import { DATE_FORMAT_ONLY_TIME } from '@constants/date';
import BookingIcon from '@components/PracticeTableBooking/BookingIcon';
import { practiceTableDaysApi } from '@services/api/practice';
import { useState } from 'react';
import RemovePracticeTableModal from '@components/PracticeTableBooking/RemovePracticeTableModal';
import { useSnackbar } from 'notistack';

interface PracticeTableBookingProps {
  practiceTableBlocks: PracticeTableBlocksType;
  activeDay: PracticeDayType;
  refresh: VoidFunction;
  tournamentName: string;
}

export type Practice = {
  table: PracticeTableType;
  time: string;
};

const PracticeTableBooking = ({
  practiceTableBlocks,
  activeDay,
  refresh,
  tournamentName,
}: PracticeTableBookingProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedPractice, setSelectedPractice] = useState<Practice>();

  const handleOpen = (practiceTable: PracticeTableType, practiceTableTime: string) => {
    setSelectedPractice({ table: practiceTable, time: practiceTableTime });
  };
  const handleClose = () => {
    setSelectedPractice(undefined);
  };

  const handleBookTable = async (values: PracticeTableBookingType) => {
    try {
      await practiceTableDaysApi.postPracticeTableBooking(values);
      refresh();
      return enqueueSnackbar('Your practice table has been successfully booked!', {
        variant: 'success',
      });
    } catch (e) {
      return enqueueSnackbar(`You can't book this table.`, { variant: 'error' });
    }
  };

  const handleRemoveTableBooking = async (bookingId: number) => {
    try {
      await practiceTableDaysApi.deletePracticeTableBooking(bookingId);
      refresh();
      handleClose();
      return enqueueSnackbar('Your reservation has been successfully canceled!', {
        variant: 'success',
      });
    } catch (e) {
      return enqueueSnackbar(`You can't cancel this reservation.`, { variant: 'error' });
    }
  };

  const handleTableClick = (
    practiceTableBlockId: number,
    practiceTableDayId: number,
    practiceTable: PracticeTableType,
    practiceTableTime: string
  ) => {
    if (practiceTable.status === 'Available') {
      return handleBookTable({
        practiceTableBlockId,
        tableId: practiceTable.practiceTableId,
      });
    }
    if (practiceTable.status === 'BookedByCurrentUser') {
      return handleOpen(practiceTable, practiceTableTime);
    }
    return;
  };

  return (
    <>
      <StyledTableContainer>
        <Table>
          <StyledTableHead>
            <TableRow>
              <TableCell></TableCell>
              {practiceTableBlocks?.data.practiceBlockDetails[0]?.practiceTables.map(
                (practiceTable, index) => (
                  <TableCell key={practiceTable.practiceTableId} align="center">
                    {practiceTable.name}
                  </TableCell>
                )
              )}
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {practiceTableBlocks?.data.practiceBlockDetails.map((practiceBlock, index) => (
              <StyledTableRow key={practiceBlock.practiceTableBlockId}>
                <StripedTimeTableCell index={index}>
                  {formatDate(practiceBlock.start, DATE_FORMAT_ONLY_TIME)}-
                  {formatDate(practiceBlock.end, DATE_FORMAT_ONLY_TIME)}
                </StripedTimeTableCell>
                {practiceBlock.practiceTables.map((practiceTable) => (
                  <PracticeTableCell key={practiceTable.name}>
                    <TableBox
                      status={practiceTable.status}
                      onClick={() =>
                        handleTableClick(
                          practiceBlock.practiceTableBlockId,
                          activeDay.practiceDayId,
                          practiceTable,
                          `${formatDate(practiceBlock.start, DATE_FORMAT_ONLY_TIME)} - ${formatDate(
                            practiceBlock.end,
                            DATE_FORMAT_ONLY_TIME
                          )}`
                        )
                      }
                    >
                      <BookingIcon status={practiceTable.status} />
                    </TableBox>
                  </PracticeTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
      {selectedPractice && (
        <RemovePracticeTableModal
          open={selectedPractice !== undefined}
          handleClose={handleClose}
          handleRemove={() => handleRemoveTableBooking(selectedPractice.table.bookingId)}
          practice={selectedPractice}
          tournamentName={tournamentName}
          day={activeDay}
        />
      )}
    </>
  );
};

export default PracticeTableBooking;
