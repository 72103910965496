import { API_PATH } from '@constants/api';
import { request } from '@services/request';
import { ApiMethod } from '@services/api/index';
import { ApplicantType } from '@typings/applicants.types';

const applicantsCreate = async (applicant: Partial<ApplicantType>) => {
  return await request(`${API_PATH.applicants}`, ApiMethod.POST, applicant);
};

export const applicantsApi = {
  applicantsCreate,
};
