import * as yup from 'yup';

const PasswordValidationSchema = yup
  .string()
  .min(12, 'Password must contain at least 12 characters')
  .matches(/^(?=.*[a-z]).*$/, {
    message: 'Password must contain 1 lowercase character (a-z)',
  })
  .matches(/^(?=.*[A-Z]).*$/, {
    message: 'Password must contain 1 uppercase character (A-Z)',
  })
  .matches(/^(?=.*\d).*$/, {
    message: 'Password must contain 1 digit (0-9)',
  })
  .matches(/^(?=.*[^a-zA-Z\d\s:]).*$/, {
    message: 'Password must contain 1 special character (punctuation)',
  });

export default PasswordValidationSchema;
