import Sponsors from '@/pages/sponsors';

export const SponsorsRoutes = [
  {
    path: '',
    element: <Sponsors />,
    exact: true,
    handle: { title: 'Authorised Sponsors', header: 'Approved Sponsors' },
  },
];
