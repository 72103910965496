import Typography from '@mui/material/Typography';

const PasswordRequirements = () => (
  <>
    <Typography variant="body1" color="textSecondary">
      Your new password must be at least 12 characters long and contain:
    </Typography>
    <Typography component="ul" variant="body1" color="textSecondary">
      <li>at least one uppercase character (A-Z),</li>
      <li>at least one lowercase character (a-z),</li>
      <li>at least one digit (0-9),</li>
      <li>at least one special character (for example: !, $, #, or %).</li>
    </Typography>
  </>
);
export default PasswordRequirements;
