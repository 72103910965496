import { SnackbarProvider } from 'notistack';
import { cloneElement, FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { wstDarkTheme, wstLightTheme } from 'src/styles';

import AuthProvider from '@contexts/auth';
import useUpdateEffect from '@hooks/useUpdateEffect';
import { PaletteMode as MuiPaletteMode } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import {
    getPasswordChangeTokenCookie, getTokenCookies, setThemeMode, useDarkModeFromCookie
} from '@utils/cookies';
import { useMatches } from 'react-router-dom';

type AppProps = PropsWithChildren<any>;

const getThemeForMode = (mode: MuiPaletteMode) => {
  return mode === 'light' ? wstLightTheme : wstDarkTheme;
};

export const App: FC<AppProps> = ({ children }) => {
  const { accessToken, refreshToken } = getTokenCookies();
  const passwordChangeToken = getPasswordChangeTokenCookie();
  const useDarkMode = useDarkModeFromCookie();
  const matches = useMatches();
  const match = matches.find(elem => !!elem.handle);

  if (match && match.handle && match.handle.title) {
    document.title = `${match.handle.title} - World Snooker Tournament Entry System`;
  }

  const [mode, setMode] = useState<MuiPaletteMode>(useDarkMode ? 'dark' : 'light');

  useUpdateEffect(() => {
    setThemeMode(mode);
  }, [mode]);

  // Update the theme only if the mode changes
  const theme = useMemo(() => getThemeForMode(mode), [mode]);

  const toggleMode = useCallback(() => {
    setMode((prev) => (prev === 'light' ? 'dark' : 'light'));
  }, []);

  const ChildWithProps = () => cloneElement(children, {
    toggleMode, themeMode: mode
  });

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider accessToken={accessToken} refreshToken={refreshToken} passwordChangeToken={passwordChangeToken}>
        <SnackbarProvider maxSnack={8}>
          <CssBaseline />
          <ChildWithProps />
        </SnackbarProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
