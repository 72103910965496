import React, { FC } from 'react';
import { Box, Modal } from '@mui/material';
import {
  BoldTypography,
  ItalicBoldTypography,
  NormalSemiBoldTypography,
  PrimaryButton,
  SecondaryButton,
  StyledInfoBox,
} from '@components/common/FormStyledComponents';
import { useTheme } from '@mui/material/styles';
import { PracticeDayType } from '@typings/practice.types';
import { formatDate } from '@utils/date';
import { DATE_FORMAT_DAY } from '@constants/date';
import { Practice } from '@components/PracticeTableBooking/PracticeTableBooking';

interface RemovePracticeTableModalProps {
  open: boolean;
  handleClose: VoidFunction;
  handleRemove: VoidFunction;
  practice: Practice;
  tournamentName: string;
  day: PracticeDayType;
}

const RemovePracticeTableModal: FC<RemovePracticeTableModalProps> = ({
  open,
  handleClose,
  handleRemove,
  practice,
  tournamentName,
  day,
}) => {
  const theme = useTheme();
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <BoldTypography variant="h4" textAlign="center">
          {tournamentName}
        </BoldTypography>

        <Box display="flex" flexDirection="row" gap={2} py={4}>
          <StyledInfoBox>
            <ItalicBoldTypography>Day:</ItalicBoldTypography>
            <ItalicBoldTypography>Table:</ItalicBoldTypography>
            <ItalicBoldTypography>Time:</ItalicBoldTypography>
          </StyledInfoBox>
          <StyledInfoBox>
            <NormalSemiBoldTypography>
              {formatDate(day.date, DATE_FORMAT_DAY)}
            </NormalSemiBoldTypography>
            <NormalSemiBoldTypography>{practice.table.name}</NormalSemiBoldTypography>
            <NormalSemiBoldTypography>{practice.time}</NormalSemiBoldTypography>
          </StyledInfoBox>
        </Box>

        <Box
          sx={{
            gap: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <PrimaryButton onClick={handleRemove}>Cancel Booking</PrimaryButton>
          <SecondaryButton onClick={handleClose}>Back To Practice Tables</SecondaryButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default RemovePracticeTableModal;
