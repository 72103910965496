import { useMemo, useState } from 'react';
import { CircularProgress, InputLabel, MenuItem, Select } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ErrorLabel, InfoLabel } from '@components/common/FormStyledComponents';
import { v4 } from 'uuid';
import FormControl from '@mui/material/FormControl';

type SelectBoxPropsDefVal = string | null;

type MenuItemContent = {
  label: string;
  value: string;
};

interface SelectBoxProps<T = any> {
  defaultValue?: SelectBoxPropsDefVal;
  disabled?: boolean;
  handleChange: (value: any) => void;
  data: {
    options: T[] | any[];
    isLoading?: boolean;
    error?: any;
  };
  isNone?: boolean;
  onClose?: (value: any) => void;
  label?: string;
  error?: string;
  name?: string;
}

const SelectBox = ({ defaultValue, handleChange, disabled, data, isNone, onClose, label, error, name }: SelectBoxProps) => {
  const inputId = useMemo(() => v4(), []);
  enum selectOpenState {
    Blurred,
    Closed,
    Open,
  }
  const [selectOpen, setSelectOpen] = useState<selectOpenState>(selectOpenState.Blurred);

  return (
    <FormControl variant="standard">
      <InputLabel htmlFor={inputId} error={!!error}>
        {label}
      </InputLabel>
      <Select
        name={name}
        disabled={disabled}
        error={!!error}
        label={label}
        inputProps={{ id: inputId }}
        fullWidth
        value={defaultValue ?? ''}
        onChange={(e) => {
          setSelectOpen(selectOpenState.Closed);
          handleChange(e.target.value);
        }}
        onClose={(e) => {
          setSelectOpen(selectOpenState.Closed);
          onClose?.(e);
        }}
        open={selectOpen === selectOpenState.Open}
        onFocus={() =>
          selectOpen === selectOpenState.Blurred && setSelectOpen(selectOpenState.Open)
        }
        onBlur={() => setSelectOpen(selectOpenState.Blurred)}
        onKeyDownCapture={(e) => {
          if (e.code === 'Tab') {
            setSelectOpen(selectOpenState.Closed);
          }
        }}
        onClick={() => selectOpen === selectOpenState.Closed && setSelectOpen(selectOpenState.Open)}
      >
        {data.isLoading ? (
          <MenuItem
            key={encodeURI(label + 'placeholder')}
            value={defaultValue ?? ''}
            defaultChecked
          >
            {defaultValue ?? <CircularProgress size={20} />}
          </MenuItem>
        ) : data.error ? (
          <MenuItem key={encodeURI(label + 'error')}>
            <ErrorOutlineIcon color="error" fontSize="medium" />
          </MenuItem>
        ) : data.options.length === 0 ? (
          <MenuItem key={encodeURI(label + 'noresults')}>
            <InfoLabel>no results</InfoLabel>
          </MenuItem>
        ) : (
          (isNone ? [{ value: 'none', label: 'None' }] : [])
            .concat(...data.options)
            .map(
              (option: MenuItemContent | string) =>
                typeof option === 'string' ? (
                  <MenuItem
                    key={`${encodeURI(label + 'item')}-${option ?? v4()}`}
                    value={option ?? ''}
                  >
                    {option}
                  </MenuItem>
                ) : (
                  <MenuItem
                    key={`${encodeURI(label + 'item')}-${option.value ?? v4()}`}
                    value={option.value ?? ''}
                  >
                    {option.label}
                  </MenuItem>
                )
            )
        )}
      </Select>
      {!!error && <ErrorLabel>{error}</ErrorLabel>}
    </FormControl>
  );
};

export default SelectBox;
