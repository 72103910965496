import { FormikProps } from 'formik';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    BoldTypography, CartDrawer, DrawerBox, PrimaryButton, SecondaryButton
} from '@components/common/FormStyledComponents';
import { Field } from '@components/layout/Field/Field';
import { Title } from '@components/layout/Title/Title';
import { Box, Checkbox, FormControlLabel, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CheckoutTerm } from '@/types/checkout.types';
import { CountryType } from '@/types/country.types';
import { onFieldChange } from '@/utils/formik';
import { CountryCodeSelect } from '../Account/CountryCodeSelect';

interface CheckoutFormProps {
  terms: CheckoutTerm[];
  formik: FormikProps<any>;
}

const CheckoutForm: FC<CheckoutFormProps> = ({ formik, terms }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const handleCancel = () => navigate('/');

  return (
    <>
      <CartDrawer>
        <DrawerBox>
          <Title title="Checkout Form" />
          <Box sx={{ mb: 2, mt: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Field label="First Name *" name="firstnames" formik={formik} />
            <Field label="Last Name *" name="lastName" formik={formik} />
            <Field label="Address *" name="address1" formik={formik} />
            <Field label="Address" name="address2" formik={formik} />
            <Field label="City *" name="city" formik={formik} />
            <CountryCodeSelect
              onChange={(country: CountryType['name']) => onFieldChange(formik, 'country2Char', country)}
              defaultValue={formik.values.country2Char}
              error={formik.touched.country2Char && formik.errors.country2Char}
            />
            <Field label="Post Code *" name="postcode" formik={formik} />
            <Field label="Phone *" name="phone" formik={formik} />
            <Field label="E-mail *" name="email" formik={formik} />
            <Field label="E-mail Cc" name="emailCc" formik={formik} />
              {terms.map((term) => (
                  <FormControlLabel
                    key={term.key}
                    control={<Checkbox />}
                    checked={formik.values[term.key]}
                    onChange={(event, value) =>
                      onFieldChange(formik, term.key, value)
                    }
                    label={
                      <BoldTypography variant="body2" color="textSecondary">
                        {term.label}
                      </BoldTypography>
                    }
                  />
              ))}
          </Box>
          <Box display="flex" flexDirection="column" gap={2} my={4}>
            {terms.map(({ key, link: { label, href }}) => (
              <Link target="_blank" key={key} href={href} color="secondary" underline="none" fontWeight="bold">
                {label}
              </Link>
            ))}
          </Box>
        </DrawerBox>
      </CartDrawer>
      <Box
        sx={{
          padding: theme.spacing(0, 4),
          gap: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
        <PrimaryButton disabled={!formik.dirty || !formik.isValid} onClick={formik.submitForm}>
          Pay
        </PrimaryButton>
      </Box>
    </>
  );
};

export default CheckoutForm;
