import { useSearchParams } from 'react-router-dom';

import TransactionSuccess from '@components/Transactions/TransactionsSuccess';

const PaymentsSuccess = () => {
  const [searchParams] = useSearchParams();
  const vendorTxCode = searchParams.get('vendorTxCode') || '';

  return <TransactionSuccess vendorTxCode={vendorTxCode as string} />;
};

export default PaymentsSuccess;
