import useSWR from 'swr';
import { ItemStringFetcher } from '@services/index';
import { CheckoutTournamentsType } from '@typings/checkout.types';
import { checkoutApi } from '@services/api/checkout';

const itemFetcher: ItemStringFetcher<{ data: CheckoutTournamentsType }> = async (tournamentsIds: string[]) =>
  await checkoutApi.getCheckoutTournaments(tournamentsIds);

export function useCheckout(tournamentsIds: string[]) {
  const { data, error, mutate } = useSWR([tournamentsIds, 'get-checkout-tournaments'], itemFetcher);

  return {
    data: data?.data || {} as CheckoutTournamentsType,
    error,
    isLoading: !data && !error,
    mutate,
  };
}
