import { API_PATH } from '@constants/api';
import { request } from '@services/request';
import { ApiMethod } from '@services/api/index';
import { MessagesType } from '@typings/messages';

const getCurrentMessages = async (): Promise<MessagesType> => {
  return await request(`${API_PATH.messages}/current`, ApiMethod.GET);
};

export const messagesApi = {
  getCurrentMessages,
};
