import { PrimaryButton, StyledPriceBox } from '@components/common/FormStyledComponents';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

interface CheckoutFooterProps {
  selectedTournaments: [] | number[];
  tournamentsPrice: number;
  handleCheckout: VoidFunction;
}

const CheckoutFooter = ({
  selectedTournaments,
  tournamentsPrice,
  handleCheckout,
}: CheckoutFooterProps) => {
  const theme = useTheme();

  return (
    <Box>
      <StyledPriceBox>
        <Typography color="secondary">
          TOTAL ({selectedTournaments.length} event
          {selectedTournaments.length > 1 ? 's' : ''})
        </Typography>
        <Typography color="secondary">£{tournamentsPrice}</Typography>
      </StyledPriceBox>
      <Box sx={{ padding: theme.spacing(0, 4) }}>
        <PrimaryButton onClick={handleCheckout} sx={{ mt: 4, mb: 1 }}>
          Checkout
        </PrimaryButton>
      </Box>
    </Box>
  );
};

export default CheckoutFooter;
