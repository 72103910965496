import useSWR from 'swr';
import { ItemFetcher } from '@services/index';
import { MessagesType } from '@typings/messages';
import { messagesApi } from '@services/api/messages';

const itemFetcher: ItemFetcher<MessagesType> = async () => await messagesApi.getCurrentMessages();

export function useMessages() {
  const { data, error, mutate } = useSWR(['get-messages'], itemFetcher);

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
}
