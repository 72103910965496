import ChangePassword from '@/pages/account/change-password';
import Profile from '@/pages/account/profile';

import { APP_PATHS } from './constants';

const { PROFILE } = APP_PATHS;

export const ProfileRoutes = [
  {
    path: '',
    element: <Profile />,
    exact: true,
    handle: { title: 'Profile details', header: 'Profile details' },
  },
  {
    path: PROFILE.CHANGE_PASSWORD,
    element: <ChangePassword />,
    handle: { title: 'Change password'},
  },
];
