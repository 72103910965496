import {
  DATE_FORMAT,
  DATE_FORMAT_DAY,
  DATE_FORMAT_ONLY_TIME,
  DATE_FORMAT_WITHOUT_YEAR
} from '@constants/date';

type DateFormatOptionsType = {
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  firstWeekContainsDate?: number;
  useAdditionalWeekYearTokens?: boolean;
  useAdditionalDayOfYearTokens?: boolean;
};

export function isDateValid(date: Date | null) {
  return date && date.toString() !== 'Invalid Date';
}

export function GetDateNonTZ(date: Date | null) {
  if (date === null || date === undefined) {
    return null;
  }
  
  return new Date(date.getTime() +- date.getTimezoneOffset() * 60000);
}

export const formatDate = (
  date?: Date | string | null,
  format = DATE_FORMAT
) => {
  if (date === null || date === undefined) {
    return undefined;
  }

  if (typeof date === 'string') {
    date = new Date(date);
  }

  if (!isDateValid(date)) {
    return '';
  }

  return formatDateToUTC(date, format);
};

const addZero = (i) => i < 10 ? '0' + i : i;

const formatDateToUTC = (date, format = DATE_FORMAT) => {
  const day = date.getDate();
  const dayFull = addZero(date.getDate());
  const month =  addZero(date.getMonth() + 1);
  const year = date.getFullYear();
  const hour = addZero(date.getUTCHours());
  const minutes = addZero(date.getMinutes());
  const dayOfWeek = getDayName(date);
  const monthName = getMonthName(date);

  switch (format){
    case DATE_FORMAT: {
      return `${dayFull}/${month}/${year}`;
    }
    case DATE_FORMAT_WITHOUT_YEAR: {
      return `${day} ${monthName}`;
    }
    case DATE_FORMAT_ONLY_TIME: {
      return `${hour}:${minutes}`;
    }
    case DATE_FORMAT_DAY: {
      return `${dayOfWeek} ${day} ${monthName}`;
    }
  }
  
  return `${day}/${month}/${year} ${hour}:${minutes}`;
}

const getDayName = (date, locale = 'en-GB') => {
  return date.toLocaleDateString(locale, {weekday: 'short'})
}

const getMonthName = (date, locale = 'en-GB') => {
  return date.toLocaleDateString(locale, {month: 'short'})
}
