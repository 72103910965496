import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import CheckoutForm from '@components/Checkout/CheckoutForm';
import { StyledPriceBox } from '@components/common/FormStyledComponents';
import { checkoutEmptyValues, checkoutSeasonTerms } from '@constants/checkout';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { checkoutApi } from '@services/api/checkout';
import { ApiError } from '@services/apiError';
import { CheckoutSeasonType, SeasonCheckoutType } from '@typings/checkout.types';
import { SeasonCheckoutValidationSchema } from '@validations/CheckoutValidationSchema';

const SeasonCheckout = () => {
  const [{ data }, setCheckoutData] = useState<{data: CheckoutSeasonType }>({} as { data: CheckoutSeasonType });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    checkoutApi.getCheckoutSeasonMembership().then(res => setCheckoutData(res));
  }, [])

  const onSubmit = async (values: SeasonCheckoutType, actions: FormikHelpers<SeasonCheckoutType>) => {
    try {
      const response = await checkoutApi.postCheckoutSeasonMembership(values);
      actions.resetForm();
      window.location.href = response.nextUrl;
    } catch (e) {
      return enqueueSnackbar((e as ApiError).title, { variant: 'error' });
    }
  };

  const formik = useFormik<SeasonCheckoutType>({
    initialValues: {
      ...checkoutEmptyValues,
      ...data,
      wpbsaConstitutionAccepted: false,
      wpbsaMembersRulesAccepted: false,
      wpbsaDisciplinaryRulesAccepted: false,
      emailCc: '',
    },
    validationSchema: SeasonCheckoutValidationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  return (
    <>
      {data &&
        <Box>
          <StyledPriceBox>
            <Typography color="secondary">
              {data.items.length && data.items[0].description}
            </Typography>
            <Typography color="secondary">£{data.items.length && data.items[0].netTotal}</Typography>
          </StyledPriceBox>
        </Box>
      }
      <CheckoutForm terms={checkoutSeasonTerms} formik={formik} />
    </>
  );
};

export default SeasonCheckout;
